import React, { useState } from 'react'
import { transparentize } from '@theme-ui/color'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import { useWindowHeight } from '@react-hook/window-size/throttled'

import { motion } from 'framer-motion'

import Navbar from '@/components/Navbar'

const ScrollActivatedNavbar = (props) => {
  const [state, setState] = useState({
    isVisible: false,
  })

  const height = useWindowHeight()

  useScrollPosition(({ prevPos, currPos }) => {
    const delta = currPos.y - prevPos.y
    const isScrollingUp = delta > 0

    const threshold = -height || -Infinity
    const isPastThreshold = currPos.y < threshold

    const isVisible = isScrollingUp && isPastThreshold

    if (isVisible !== state.isVisible) {
      setState({
        isVisible,
      })
    }
  })

  return (
    <motion.div
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 'scrollNav',
        bg: transparentize('background', 0.3),
        backdropFilter: 'blur(10px)',
      }}
      animate={{ y: state.isVisible ? 0 : '-100%' }}
      initial={false}
      transition={{ ease: 'easeOut', duration: 0.25 }}
    >
      <Navbar colorOverride="mediumGray" />
    </motion.div>
  )
}

export default ScrollActivatedNavbar
