import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function MdxImg({ src, alt }) {
  const { allContentfulAsset } = useStaticQuery(graphql`
    query {
      allContentfulAsset {
        edges {
          node {
            file {
              url
            }
            title
            localFile {
              id
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
  `)

  const image = allContentfulAsset.edges.find((edge) => {
    return edge.node.file.url === src
  })

  if (!image) {
    return null
  }

  return (
    <GatsbyImage
      sx={{
        '[data-placeholder-image]': {
          filter: 'blur(10px)',
        },
      }}
      image={image.node.localFile.childImageSharp.gatsbyImageData}
      alt={image.node.title}
      placeholder="blurred"

      // objectFit="cover"
      // objectPosition="50% 50%"
      // placeholderStyle={{
      //   transform: 'scale(1.1)',
      //   filter: 'blur(20px)',
      // }}
    />
  )
}
