import { Box, Flex, ThemeUIStyleObject } from 'theme-ui'
import { motion, useCycle } from 'framer-motion'
import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { SVG, Line, Svg } from '@svgdotjs/svg.js'
import useSize from '@react-hook/size'

const speed = 100

export const Arrow = ({ color, strokeWidth = 3, ...rest }) => {
  const [isHovered, setIsHovered] = useState(false)

  const containerRef = useRef(null)

  const sizeRef = useRef(null)
  const [w, h] = useSize(sizeRef)

  const svgRef = useRef(null)
  const topRef = useRef(null)
  const middleRef = useRef(null)
  const bottomRef = useRef(null)
  const polyRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    if (svgRef.current) {
      return
    }

    const draw = SVG().addTo(containerRef.current).size(60, 60)

    draw.attr({ vectorEffect: 'non-scaling-stroke' })

    polyRef.current = draw
      .polyline([
        [30, 10],
        [58, 30],
        [30, 50],
      ])
      .stroke({ width: strokeWidth })
      .attr({
        stroke: '#FFF',
        fill: 'none',
        'stroke-linecap': 'round butt',
        'vector-effect': 'non-scaling-stroke',
      })

    // topRef.current = draw
    //   .line(10, 10, 45, 30)
    //   .stroke({ width: strokeWidth })
    //   .attr({
    //     stroke: '#000',
    //     'stroke-linecap': 'round butt',
    //     vectorEffect: 'non-scaling-stroke',
    //   })

    middleRef.current = draw
      .line(45, 30, 45, 30)
      .stroke({ width: strokeWidth })
      .attr({
        stroke: '#000',
        'stroke-linecap': 'round butt',
        vectorEffect: 'non-scaling-stroke',
      })

    // bottomRef.current = draw
    //   .line(10, 50, 45, 30)
    //   .stroke({ width: strokeWidth })
    //   .attr({
    //     stroke: '#000',
    //     'stroke-linecap': 'butt round',
    //     vectorEffect: 'non-scaling-stroke',
    //   })

    svgRef.current = draw
  }, [])

  useEffect(() => {
    if (!svgRef.current) {
      return
    }

    const r = w / 60

    if (isHovered) {
      polyRef.current.animate(speed).plot([
        [45 * r, 20 * r],
        [58 * r, 30 * r],
        [45 * r, 40 * r],
      ])

      middleRef.current.animate(speed).attr({
        x1: 10 * r,
        y1: 30 * r,
        x2: 58 * r,
        y2: 30 * r,
      })
    } else {
      polyRef.current.animate(speed).plot([
        [30 * r, 10 * r],
        [58 * r, 30 * r],
        [30 * r, 50 * r],
      ])

      middleRef.current.animate(speed).attr({
        x1: 58 * r,
        y1: 30 * r,
        x2: 58 * r,
        y2: 30 * r,
      })
    }

    // if (isHovered) {
    //   topRef.current.animate(speed).attr({
    //     x1: 45 * r,
    //     y1: 20 * r,
    //     x2: 58 * r,
    //     y2: 30 * r,
    //   })

    //   bottomRef.current.animate(speed).attr({
    //     x1: 45 * r,
    //     y1: 40 * r,
    //     x2: 58 * r,
    //     y2: 30 * r,
    //   })

    //   middleRef.current.animate(speed).attr({
    //     x1: 10 * r,
    //     y1: 30 * r,
    //     x2: 58 * r,
    //     y2: 30 * r,
    //   })
    // } else {
    //   topRef.current.animate(speed).attr({
    //     x1: 30 * r,
    //     y1: 10 * r,
    //     x2: 58 * r,
    //     y2: 30 * r,
    //   })

    //   bottomRef.current.animate(speed).attr({
    //     x1: 30 * r,
    //     y1: 50 * r,
    //     x2: 58 * r,
    //     y2: 30 * r,
    //   })

    //   middleRef.current.animate(speed).attr({
    //     x1: 58 * r,
    //     y1: 30 * r,
    //     x2: 58 * r,
    //     y2: 30 * r,
    //   })
    // }
  }, [isHovered, w, h])

  return (
    <motion.div {...rest} ref={sizeRef}>
      <Box
        ref={containerRef}
        sx={{
          width: '100%',
          height: '100%',
          svg: {
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            line: {
              stroke: 'white',
            },
          },
          position: 'relative',
        }}
        onMouseEnter={() => {
          setIsHovered(true)
        }}
        onMouseLeave={() => {
          setIsHovered(false)
        }}
      ></Box>
    </motion.div>
  )
}

export default Arrow
