import React from 'react'

import { Box } from 'theme-ui'

const Caption = (props) => (
  <Box
    sx={{
      fontFamily: 'body',
      fontSize: [0, 1, 2],
      lineHeight: 1.5,
      mt: [2],
    }}
    {...props}
  />
)

export default Caption
