const X = (props) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <g id="Layer_2">
      <polygon points="28.7,4.7 27.3,3.3 16,14.6 4.7,3.3 3.3,4.7 14.6,16 3.3,27.3 4.7,28.7 16,17.4 27.3,28.7 28.7,27.3 17.4,16" />
    </g>
  </svg>
)

export default X
