export default (ratio = 1) => ({
  overflow: 'hidden',
  position: 'relative',
  ':before': {
    content: '""',
    display: 'block',
    height: 0,
    paddingTop: `${(1 / ratio) * 100}%`,
  },
})
