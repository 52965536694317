import {
  line,
  curveCardinal,
  curveCatmullRom,
  curveNatural,
  curveLinear,
  curveLinearClosed,
  curveBasis,
  curveBundle,
  curveStep,
} from 'd3-shape'

export const getLineLinksCount = (width, height) => {
  const defaultValue = Math.max(2, Math.round(width / (height / Math.SQRT2)))
  return defaultValue % 2 === 0 ? defaultValue + 1 : defaultValue
}

export const computeLinePath = (
  w,
  h,
  strokeWidth,
  interpolation = 'linear'
) => {
  const validWidth = w > 0
  const validHeight = h > 0
  const validDimensions = validWidth && validHeight
  if (!validDimensions) {
    return ''
  }

  let linksCount = getLineLinksCount(w, h)

  if (interpolation !== 'linear') {
    linksCount = Math.ceil(linksCount / 8) * 8 + 1
  }

  const points = new Array(linksCount).fill(0).map((_, ii) => {
    const isEven = ii % 2 === 0
    const usableW = w
    const usableH = h - strokeWidth
    // const usableH = h
    const xIncrement = usableW / (linksCount - 1)
    const midY = h / 2.0

    const maxOffset = usableH / 2

    let y = 0

    if (interpolation === 'linear') {
      y = midY + (isEven ? -maxOffset : maxOffset)
    } else {
      y = ((Math.sin((ii * Math.PI) / 2) * usableH) / 2) * Math.sqrt(2) + h / 2
    }

    // const offset = isEven ? maxOffset : -maxOffset
    // const offset = Math.sin(ii) * maxOffset

    return [ii * xIncrement, y]
  })

  switch (interpolation) {
    case 'basis':
      return line().curve(curveBasis)(points)
    case 'bundle':
      return line().curve(curveBundle)(points)
    case 'cardinal':
      return line().curve(curveCardinal)(points)
    case 'catmullRom':
      return line().curve(curveCatmullRom.alpha(0.5))(points)
    case 'linear':
      return line().curve(curveLinear)(points)
    case 'natural':
      return line().curve(curveNatural)(points)
    case 'step':
      return line().curve(curveStep)(points)
    default:
      return line().curve(curveLinear)(points)
  }
}

export const computeCaretPath = (w, h, strokeWidth) => {
  const validWidth = w > 0
  const validHeight = h > 0
  const validDimensions = validWidth && validHeight
  if (!validDimensions) {
    return ''
  }

  const points = [
    [0, strokeWidth / 2],
    [w - strokeWidth / 2, h / 2],
    [0, h - strokeWidth / 2],
  ]

  return line().curve(curveLinear)(points)
}

export const computeArrowPath = (w, h, strokeWidth) => {
  const validWidth = w > 0
  const validHeight = h > 0
  const validDimensions = validWidth && validHeight
  if (!validDimensions) {
    return ''
  }

  const points = [
    [0, strokeWidth / 2],
    [w - strokeWidth / 2, h / 2],
    [0, h - strokeWidth / 2],
    // [0, 0],
  ]

  return line().curve(curveLinearClosed)(points)
}
