import React from 'react'
import { AnimatePresence } from 'framer-motion'
// import ReactCSSTransitionReplace from 'react-css-transition-replace'

// const Transition = ({ children, location }) => {
//   return (
//     <ReactCSSTransitionReplace
//       transitionName="cross-fade"
//       transitionEnterTimeout={1000}
//       transitionLeaveTimeout={400}
//     >
//       <div
//         css={{ position: 'relative' }}
//         key={location ? location.pathname : ''}
//       >
//         {children}
//       </div>
//     </ReactCSSTransitionReplace>
//   )
// }

const Transition = ({ children = null }) => {
  return <AnimatePresence>{children}</AnimatePresence>
}

export default Transition
