// https://theme-ui.com/getting-started

// Theme object contains color, typography, and layout config

// Use module.exports instead of export default
// gatsby-config only supports module.exports

module.exports = {
  root: {},

  breakpoints: ['40em', '56em', '96em', '120em'],

  fonts: {
    body:
      'EuclidFlexRegular, system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
    heading:
      'EuclidFlexMedium, system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
    monospace: 'Menlo, monospace',
  },

  fontSizes: [17, 18, 20, 26, 32, 48, 64, 96, 128],

  // margin and padding
  space: [0, 12, 16, 32, 40, 48, 64, 96, 128, 192, 256],

  colors: {
    // overscrollBehaviorY: 'none',
    background: '#EDEEEE',
    text: '#666666',
    blue: '#1006EE',
    lightGray: '#EDEEEE',
    mediumGray: '#666666',
    // darkGray: '#221F1F',
    darkGray: '#323232',
  },

  zIndices: {
    nav: 11,
    scrollNav: 12,
    menu: 13,
    gridOverlay: 14,
  },

  sizes: {
    nav: '140px',
  },

  buttons: {
    primary: {
      boxSizing: 'border-box',
      minWidth: 0,
      outline: 'none',
      border: 'none',
      borderRadius: 0,
    },
    burger: {
      boxSizing: 'border-box',
      outline: 'none',
      border: 'none',
      borderRadius: 0,
      bg: 'transparent',
      width: '60px',
      height: '60px',
      padding: 0,
      lineHeight: '0px',
      cursor: 'pointer',
      verticalAlign: 'top',
    },
    projectNavCard: {
      width: '100%',
      outline: 'none',
      border: 'none',
      borderRadius: 0,
      bg: 'transparent',
      padding: 0,
      lineHeight: '0px',
      cursor: 'pointer',
      fontFamily: 'heading',
      color: 'mediumGray',
    },

    logomark: {
      boxSizing: 'border-box',
      outline: 'none',
      border: 'none',
      borderRadius: 0,
      bg: 'transparent',

      padding: 0,
      lineHeight: '0px',
      cursor: 'pointer',
      verticalAlign: 'top',
    },

    gallery: {
      borderRadius: 0,
      border: 'none',
      bg: 'transparent',
      overflow: 'visible',
      px: 0,
      py: 0,
      cursor: 'pointer',
      // bg: 'transparent',
      // bg: 'red',
    },
  },

  styles: {
    root: {
      fontFeatureSettings: 'kern',
      fontKerning: 'normal',
      textRendering: 'optimizeLegibility',
      backgroundColor: 'lightGray',
      color: 'mediumGray',
      // uses the theme values provided above
      fontFamily: 'body',
      fontWeight: 'body',
      overscrollBehaviorY: 'none',
      width: '100%',
      minHeight: '100vh',
      a: {
        color: 'mediumGray',
        '&:visited': {
          color: 'mediumGray',
        },
        '&:hover': {
          color: 'blue',
        },

        outline: 'none',
      },
    },
  },

  timings: {
    pageTransition: 0.5,
    textTransition: 0.35,
  },
}

// export default theme
