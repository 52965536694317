import React, { useEffect } from 'react'
import LazyLoad, { forceCheck } from 'react-lazyload'
import { motion } from 'framer-motion'

import Grid from '@/components/Grid'
import { HalfColumn } from '@/components/Column'
import ProjectCard from '@/components/ProjectCard'

// const grid = {
//   show: {
//     transition: {
//       staggerChildren: 0.2,
//     },
//   },
//   hidden: {
//     transition: {},
//   },
// }

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

const ProjectsGrid = ({ projects = [] }) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('Check')
  //     forceCheck()
  //   }, 1000)
  // })

  return (
    <motion.div
      sx={{ minHeight: '100vh' }}
      transition={{ duration: 0.1 }}
      initial={{ opacity: 0.0 }}
      animate={{ opacity: 1.0 }}
      onAnimationComplete={() => {
        forceCheck()
      }}
    >
      <Grid>
        {projects.map((p) => {
          const ref = React.createRef()
          return (
            <HalfColumn key={p.title}>
              <LazyLoad resize>
                <ProjectCard
                  id={p.id}
                  aspectRatio={1920 / 1280}
                  title={p.title}
                  client={p.client}
                  featuredImage={p.featuredImage}
                  url={`/work/${p.slug}`}
                  {...p.frontmatter}
                  ref={ref}
                />
              </LazyLoad>
            </HalfColumn>
          )
        })}
      </Grid>
    </motion.div>
  )
}

export default ProjectsGrid
