import { observer } from 'mobx-react'
import { Flex, Box, Button } from 'theme-ui'
import { Link } from 'gatsby'
import { useLocation, useMatch } from '@reach/router'

import Burger from '@/components/Burger'
import Logomark from '@/components/Logomark'
import NavbarLink from './NavbarLink'

const Navbar = observer(({ colorOverride }) => {
  const isHome = !!useMatch(`/`)
  const color = isHome ? 'white' : 'mediumGray'
  return (
    <Flex
      sx={{
        px: 2,
        width: '100%',
        zIndex: 'nav',
        height: '72px',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Link to="/">
        <Logomark
          sx={{
            ml: 1,
            height: '44px',
            width: 'auto',
            path: {
              fill: colorOverride || color,
            },
          }}
        />
      </Link>

      <Burger
        sx={{
          display: ['inline-block', 'inline-block', 'none'],
          svg: {
            line: {
              stroke: colorOverride || color,
            },
          },
        }}
      />
      <Flex
        sx={{
          display: ['none', 'none', 'flex'],
          height: '100%',
          alignItems: 'center',

          mr: 1,
          gap: 3,
        }}
      >
        <NavbarLink to="/work" title="Work" colorOverride={colorOverride} />
        {/* <NavbarLink to="/news" title="News" colorOverride={colorOverride} /> */}
        <NavbarLink to="/about" title="About" colorOverride={colorOverride} />
      </Flex>
    </Flex>
  )
})

export default Navbar
