import { action, computed, makeObservable, observable, runInAction } from 'mobx'

class AppStore {
  rootStore
  isMenuOpen = false

  constructor(rootStore) {
    makeObservable(this, {
      rootStore: false,

      // Observables
      isMenuOpen: observable,

      // Action
      openMenu: action,
      closeMenu: action,
      toggleMenu: action,
    })

    this.rootStore = rootStore
  }

  openMenu() {
    runInAction(() => {
      this.isMenuOpen = true
    })
  }

  closeMenu() {
    runInAction(() => {
      this.isMenuOpen = false
    })
  }

  toggleMenu() {
    runInAction(() => {
      this.isMenuOpen = !this.isMenuOpen
    })
  }
}

export default AppStore
