import React from 'react'

const SVG = React.forwardRef((props, ref) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 188.98 118.11"
    ref={ref}
    {...props}
  >
    <polygon points="0.39 0.63 0.39 56.02 34.38 56.02 34.38 43.61 12.86 43.61 12.86 0.63 0.39 0.63" />
    <path d="M35.86,7a7,7,0,1,1,14,0,6.71,6.71,0,0,1-1.56,4.44H37.42A6.72,6.72,0,0,1,35.86,7M37,17H48.72V56H37Z" />
    <path d="M52.15,17H63.93v2.42a14,14,0,0,1,9-3c11,0,17,7.33,17,17.86V56H78.11V36.36c0-5.23-2-9.21-7.17-9.21a9.94,9.94,0,0,0-7,3.35V56H52.15Z" />
    <path d="M91.68,36.51c0-11.47,8.11-20.13,20.11-20.13,9.9,0,18.79,6.47,18.79,18.8a26.25,26.25,0,0,1-.23,3.43H103c.55,5.23,5,7.26,8.5,7.26A8.93,8.93,0,0,0,119.82,41l9.36,6.32a20.5,20.5,0,0,1-17.62,9.36A19.87,19.87,0,0,1,91.68,36.51m11.38-4.76H119c-.62-4.14-4.05-5.93-7.87-5.93-2.81,0-6.94,1.25-8,5.93" />
    <path d="M0,62.09H20.81A16.51,16.51,0,0,1,37.27,78.56a13.12,13.12,0,0,1-3.9,9.52,14.26,14.26,0,0,1,7.8,13A16.53,16.53,0,0,1,24.4,117.48H0ZM19.41,84.48A5.5,5.5,0,0,0,25.18,79a5.56,5.56,0,0,0-5.77-5.54H12.47v11ZM23.23,106A5.5,5.5,0,0,0,29,100.56,5.56,5.56,0,0,0,23.23,95H12.47v11Z" />
    <path d="M43,78.48H54.81v3.43a10.86,10.86,0,0,1,8.89-4.06,14.11,14.11,0,0,1,4.21.63V90.73a13.24,13.24,0,0,0-6-1.33A11.32,11.32,0,0,0,54.81,92v25.51H43Z" />
    <path d="M67.2,98c0-11.47,8.11-20.13,20.11-20.13,9.9,0,18.79,6.48,18.79,18.8a26.22,26.22,0,0,1-.23,3.43H78.51c.55,5.23,5,7.26,8.5,7.26a8.93,8.93,0,0,0,8.34-4.92l9.36,6.32a20.5,20.5,0,0,1-17.62,9.36A19.87,19.87,0,0,1,67.2,98m11.38-4.76h15.9c-.62-4.14-4.05-5.93-7.87-5.93-2.81,0-6.94,1.25-8,5.93" />
    <path d="M134.56,115.22a13.56,13.56,0,0,1-8.58,2.89c-11.54,0-18.86-9.44-18.86-20.13S114.45,77.85,126,77.85a13.61,13.61,0,0,1,8.58,3V78.48h11.77v39H134.56Zm0-11.08V91.89a7.92,7.92,0,0,0-6.63-3.28c-5.61,0-9,4.21-9,9.36s3.35,9.36,9,9.36a7.85,7.85,0,0,0,6.63-3.2" />
    <polygon points="148.9 62.09 160.68 62.09 160.68 96.27 173.38 78.48 186.95 78.48 173.69 96.97 188.97 117.48 175.1 117.48 160.68 98.06 160.68 117.48 148.9 117.48 148.9 62.09" />
  </svg>
))

export default SVG
