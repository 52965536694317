const ChevronRight = (props) => (
  // <svg
  //   x="0px"
  //   y="0px"
  //   viewBox="0 0 32 32"
  //   enableBackground="new 0 0 32 32"
  //   xmlSpace="preserve"
  //   {...props}
  // >
  //   <polygon points="22,16 12,26 10.6,24.6 19.2,16 10.6,7.4 12,6 " />
  // </svg>
  <svg viewBox="0 0 32 32" {...props}>
    <polygon points="12 6 12 8.8 19.2 16 12 23.2 12 26 21 17 21 15 12 6" />
  </svg>
)

export default ChevronRight
