import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Button } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion, AnimatePresence } from 'framer-motion'
import ScrollLock from 'react-scrolllock'
import { useWindowSize } from '@react-hook/window-size'

import X from '@/components/Svg/X'

const speed = 0.25

export const ZoomImg = ({ src, alt }) => {
  const [zoom, setZoom] = useState(false)
  const [layoutComplete, setLayoutComplete] = useState(true)

  const [windowWidth, windowHeight] = useWindowSize()
  const mountRef = React.useRef(null)
  const ref = React.useRef(null)
  const zoomRef = React.useRef(null)

  const [isAnimating, setIsAnimating] = useState(false)
  const { allContentfulAsset } = useStaticQuery(graphql`
    query {
      allContentfulAsset {
        edges {
          node {
            file {
              url
            }
            title
            localFile {
              id
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
  `)

  const image = allContentfulAsset.edges.find((edge) => {
    return edge.node.file.url === src
  })

  if (!image?.node?.localFile) {
    return null
  }

  const gid = image?.node?.localFile?.childImageSharp?.gatsbyImageData
  const imageAspect = gid.width / gid.height

  const preventScroll = React.useRef((e) => {
    e.preventDefault()
  })

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true
      return
    }
    setLayoutComplete(false)

    if (zoom) {
      document.addEventListener('wheel', preventScroll.current, {
        passive: false,
      })
    } else {
      document.removeEventListener('wheel', preventScroll.current)
    }
  }, [zoom])

  const windowAspect = windowWidth / windowHeight

  var w = windowWidth
  var h = windowHeight

  if (windowAspect > imageAspect) {
    h = windowHeight
    w = imageAspect * windowHeight
  } else if (windowAspect < imageAspect) {
    w = windowWidth
    h = windowWidth / imageAspect
  }

  // const zoomWidth =

  // const zoomHeight =

  return (
    <Box
      // sx={{ position: 'relative', zIndex: isAnimating || zoom ? 9999 : 0 }}
      sx={{
        position: 'relative',
        backfaceVisibility: 'hidden',
        zIndex: layoutComplete && !zoom ? 0 : 9999,
      }}
      ref={ref}
    >
      <motion.div
        onLayoutAnimationComplete={() => {
          // console.log('Return animation complete')
          setLayoutComplete(true)
        }}
        layout
        layoutId={`${image.node.localFile.id}`}
        transition={{
          duration: speed,
          ease: 'easeInOut',
        }}
      >
        <GatsbyImage
          onClick={() => {
            setZoom(!zoom)
          }}
          sx={{
            backfaceVisibility: 'hidden',
            pt: 0,
            cursor: 'url("/cursors/plus-filled.svg") 30 30, auto',
          }}
          image={image.node.localFile.childImageSharp.gatsbyImageData}
          alt={image.node.title}
        />
      </motion.div>

      {/* <ScrollLock isActive={zoom} /> */}
      <AnimatePresence>
        {zoom && (
          <motion.div
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bg: 'transparent',
            }}
            // onWheel={() => {
            //   if (!zoom || isAnimating) {
            //     return
            //   }

            //   setZoom(false)
            // }}
          >
            {/** Backdrop */}
            <motion.div
              ref={zoomRef}
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              exit={{
                opacity: 0.0,
                transition: {
                  delay: 0,
                  duration: 0,
                },
              }}
              transition={{ duration: speed, delay: speed / 2 }}
              sx={{
                backfaceVisibility: 'hidden',
                position: 'absolute',
                bg: 'background',
                zIndex: 0,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                // cursor: zoom ? 'url("/cursors/x.svg") 30 30, auto' : 'auto',
              }}
            />

            {/** Close button */}
            <motion.div
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              exit={{
                opacity: 0.0,
                transition: {
                  delay: 0,
                },
              }}
              transition={{ duration: speed, delay: speed }}
            >
              <Button
                onClick={() => setZoom(false)}
                sx={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  zIndex: 99,
                  p: 0,
                  bg: 'rgba(0, 0, 0.5)',
                  backdropFilter: 'blur(20px)',
                  borderRadius: '30px',
                  width: '64px',
                  height: '32px',
                  lineHeight: 0,
                  cursor: 'pointer',
                  fontFamily: 'body',
                  mr: 1,
                  mt: 1,
                }}
              >
                close
              </Button>
            </motion.div>

            <motion.div
              sx={{
                bg: 'background',
                position: 'relative',
                top: 0,
                left: 0,
                width: `${w}px`,
                height: `${h}px`,
                zIndex: 10,
                mx: 'auto',
                my: 'auto',
                // width: '100%',
                // height: '100%',
                // bg: 'red',
                // position: 'absolute',
                // top: '50%',
                // left: '50%',
                // transform: 'translate3d(-50%, -50%, 0)',
              }}
              transition={{
                duration: speed * 2,
              }}
              onLayoutAnimationComplete={() => {
                // console.log('Zoom animation complete')
                setLayoutComplete(true)
              }}
              onAnimationStart={() => {
                // console.log('Animation starting')
                setIsAnimating(true)
              }}
              onAnimationComplete={(definition) => {
                // console.log('Completed animating', definition)
                setIsAnimating(false)
              }}
              layoutId={`${image.node.localFile.id}`}
              layout
            >
              <GatsbyImage
                onClick={() => {
                  setZoom(!zoom)
                }}
                sx={{
                  backfaceVisibility: 'hidden',
                  // zIndex: 99,
                  cursor: zoom
                    ? 'url("/cursors/x-filled.svg") 30 30, auto'
                    : 'auto',
                  width: '100%',
                  height: '100%',
                  opacity: '1.0 important!',
                  img: {
                    objectFit: 'contain !important',
                  },
                }}
                loading="eager"
                image={image.node.localFile.childImageSharp.gatsbyImageData}
                alt={image.node.title}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}

export default ZoomImg
