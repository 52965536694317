const Instagram = (props) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <circle cx="22.4" cy="9.6" r="1.4" />
    <path
      d="M16,9.8c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S19.4,9.8,16,9.8L16,9.8z M16,20c-2.2,0-4-1.8-4-4
	s1.8-4,4-4s4,1.8,4,4S18.2,20,16,20z"
    />
    <path
      d="M16,6.2c3.2,0,3.6,0,4.8,0.1c0.8,0,1.5,0.1,2.2,0.4c1,0.4,1.9,1.2,2.3,2.3c0.3,0.7,0.4,1.5,0.4,2.2c0.1,1.3,0.1,1.6,0.1,4.8
	s0,3.6-0.1,4.8c0,0.8-0.1,1.5-0.4,2.2c-0.4,1-1.2,1.9-2.3,2.3c-0.7,0.3-1.5,0.4-2.2,0.4c-1.3,0.1-1.6,0.1-4.8,0.1s-3.6,0-4.8-0.1
	c-0.8,0-1.5-0.1-2.2-0.4c-1-0.4-1.9-1.2-2.3-2.3c-0.3-0.7-0.4-1.5-0.4-2.2c-0.1-1.3-0.1-1.6-0.1-4.8s0-3.6,0.1-4.8
	c0-0.8,0.1-1.5,0.4-2.2C7,7.9,7.9,7,8.9,6.6c0.7-0.3,1.5-0.4,2.2-0.4C12.4,6.2,12.8,6.2,16,6.2 M16,4c-3.3,0-3.7,0-4.9,0.1
	c-1,0-2,0.2-2.9,0.6C6.5,5.3,5.3,6.5,4.6,8.1c-0.3,0.9-0.5,1.9-0.6,2.9C4,12.3,4,12.7,4,16s0,3.7,0.1,4.9c0,1,0.2,2,0.6,2.9
	c0.6,1.6,1.9,2.9,3.5,3.5c0.9,0.3,1.9,0.5,2.9,0.6C12.3,28,12.7,28,16,28s3.7,0,4.9-0.1c1,0,2-0.2,2.9-0.6c1.6-0.6,2.9-1.9,3.5-3.5
	c0.3-0.9,0.5-1.9,0.6-2.9C28,19.7,28,19.3,28,16s0-3.7-0.1-4.9c0-1-0.2-2-0.6-2.9c-0.6-1.6-1.9-2.9-3.5-3.5
	c-0.9-0.3-1.9-0.5-2.9-0.6C19.7,4,19.3,4,16,4z"
    />
  </svg>
)

export default Instagram
