import Grid from '@/components/Grid'
import { Box, Button, useThemeUI } from 'theme-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { Column } from '@/components/Column'
import { useWindowSize } from '@react-hook/window-size/throttled'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import RpathOr from 'ramda/src/pathOr'
import { navigate } from 'gatsby'

import pctString from '@/helpers/pctString'

const GalleryItem = ({ item, isActive, ...rest }) => {
  const { theme } = useThemeUI()
  const [windowWidth, windowHeight] = useWindowSize()

  const videoAspectRatio = 1920 / 1080
  const windowAspectRatio = windowWidth / windowHeight

  let scale = 1.0

  let videoWidth = windowWidth
  let videoHeight = videoWidth / videoAspectRatio

  if (videoAspectRatio > windowAspectRatio) {
    scale = windowHeight / videoHeight + 0.01
  }

  if (videoAspectRatio < windowAspectRatio) {
    scale = videoHeight / windowHeight + 0.01
  }

  const project = item.project

  const body = RpathOr(null, ['content', 'childMdx', 'body'], item)

  // console.log('Active?', item.project.title, isActive)
  return (
    <Box
      sx={{
        position: 'absolute',
        overflow: 'hidden',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bg: 'transparent',
        '.gatsby-image-wrapper': {
          width: '100%',
          height: '100%',
          img: {
            objectFit: 'cover',
          },
        },
        iframe: {
          width: '100% !important',
          height: '100vh !important',
          transform: `scale3d(${scale}, ${scale}, 1.0)`,
        },
      }}
      {...rest}
    >
      {/**
       * Veil
       */}
      <Box
        className="gallery-veil"
        sx={{
          bg: 'black',
          opacity: 0.35,
          pointerEvents: 'none',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 2,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {body && (
          <MDXRenderer foo="bar" isActive={isActive}>
            {body}
          </MDXRenderer>
        )}
      </Box>
      <AnimatePresence>
        {isActive && (
          <motion.div
            sx={{
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translate3d(0, -50%, 0)',

              // bottom: 0,
              // left: 0,
              width: '100%',
              color: item.colorTheme === 'light' ? theme.colors.text : 'white',

              zIndex: 3,
            }}
          >
            <Grid sx={{}}>
              <Column
                width={['100%', '100%', pctString(13 / 16)]}
                sx={{
                  mb: 0,
                }}
              >
                <motion.div
                  key="gallery-card"
                  initial={{
                    opacity: 0.0,
                  }}
                  animate={{
                    opacity: 1.0,
                  }}
                  transition={{
                    duration: 1.0,
                    delay: 0.25,
                  }}
                  exit={{ opacity: 0 }}
                  sx={{
                    cursor: 'pointer',
                    mb: 0,
                    color: 'white',

                    // backdropFilter: 'blur(10px)',
                  }}
                  onClick={() => {
                    navigate(`/work/${project.slug}`)
                  }}
                >
                  <h1
                    sx={{
                      m: 0,
                      lineHeight: 1.25,
                      fontWeight: 200,
                    }}
                  >
                    {item.project.client}
                  </h1>
                  <h1
                    sx={{
                      m: 0,
                      mb: 0,
                      fontSize: 6,
                      mb: 3,
                      lineHeight: 1.0,
                    }}
                  >
                    {item.project.title}
                  </h1>
                  <Box
                    sx={{
                      fontSize: [2, 3, 4],
                      m: 0,
                      lineHeight: 1.25,
                      fontWeight: 200,
                      maxWidth: '960px',
                    }}
                  >
                    {item.project.summary}
                  </Box>
                </motion.div>
              </Column>
            </Grid>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}

export default GalleryItem
