import { useState } from 'react'
import { Box, Button } from 'theme-ui'

import * as theme from '@/theme'

import Caret from '@/components/Caret'

import ChevronRight from '@/components/Svg/ChevronRight'

const GalleryNavButton = ({ color, ...rest }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Button
      variant="gallery"
      sx={{
        width: 60 / Math.sqrt(2),
        overflow: 'visible',
        color: color || theme.colors.text,
      }}
      {...rest}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      onMouseOver={() => {
        setIsHovered(true)
      }}
      {...rest}
    >
      <ChevronRight
        sx={{
          width: '60px',
          height: '60px',
          polygon: {
            fill: color || theme.colors.text,
          },
        }}
      ></ChevronRight>
    </Button>
  )
}

export default GalleryNavButton
