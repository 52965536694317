import React from 'react'
import { Box, jsx } from 'theme-ui'

export default ({ offset = false, ...rest }) => (
  <Box
    sx={{
      position: 'relative',
      mt: 4,
      pt: offset === true ? 8 : 0,
    }}
    {...rest}
  />
)
