import { Box } from 'theme-ui'
import { useEffect, useRef, useState } from 'react'
import Vimeo from '@vimeo/player'

// MDX plugin will force camelcase props

const VimeoPlayer = (props) => {
  const ref = useRef()

  const { src, ...rest } = props

  console.log('>>>', props)

  useEffect(() => {
    console.log('Player mount', props)
  }, [])

  return (
    <Box ref={ref.current} {...rest}>
      {/* <Vimeo
        autoplay={true}
        background={background}
        controls={controls}
        height={height}
        loop={loop}
        muted={muted}
        paused={false}
        responsive={responsive}
        showPortrait={showPortrait}
        video={video}
        width={width}
        onReady={(player) => {
          // Vimeo player API has loaded
          console.log('VimeoPlayer >> onReady')
        }}
        onError={(error) => {
          // Error has occured
          console.log('VimeoPlayer >> onError', error)
        }}
        onPlay={(event) => {
          // Triggered when video plays
          console.log('VimeoPlayer >> onPlay', event)
        }}
        onPause={(event) => {
          // Triggered when video pauses
          console.log('VimeoPlayer >> onPause', event)
        }}
        onEnd={(event) => {
          // Triggered when video ends
          // console.log('VimeoPlayer >> onEnd', event)
        }}
      ></Vimeo> */}
    </Box>
  )
}

export default VimeoPlayer
