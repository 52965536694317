import React from 'react'

import aspectRatioCss from '@/helpers/aspectRatio.css'

const AspectRatio = React.forwardRef(
  (
    { aspectRatio = 1, children = null, contentRect, proxyRef, ...rest },
    ref
  ) => (
    <div css={aspectRatioCss(aspectRatio)} {...rest} ref={ref}>
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </div>
    </div>
  )
)

export default AspectRatio
