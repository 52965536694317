import { Box, Button } from 'theme-ui'
import { observer } from 'mobx-react'
import React, { useRef, useEffect, useState, useCallback } from 'react'
import { SVG, Line, Svg } from '@svgdotjs/svg.js'

const strokeWidth = 3.0
const speed = 100

const MuteButton = observer(({ playerState, ...rest }) => {
  const [isHovered, setIsHovered] = useState(false)

  const containerRef = useRef(null)
  const svgRef = useRef(null)
  const polyRef = useRef(null)

  const strikeRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    if (svgRef.current) {
      return
    }

    const draw = SVG().addTo(containerRef.current).size(60, 60)
    svgRef.current = draw

    polyRef.current = draw
      .polygon([
        [20, 25],
        [30, 25],
        [40, 15],
        [40, 45],
        [30, 35],
        [20, 35],
      ])
      .stroke({ width: strokeWidth })
      .attr({
        stroke: '#FFF',
        fill: 'none',
        'stroke-linecap': 'round butt',
        'vector-effect': 'non-scaling-stroke',
      })

    // pauseLeftRef.current = draw
    //   .line([
    //     [20, 15],
    //     [20, 45],
    //   ])
    //   .stroke({ width: strokeWidth })
    //   .attr({
    //     stroke: '#FFF',
    //     fill: 'none',
    //     'stroke-linecap': 'round butt',
    //     'vector-effect': 'non-scaling-stroke',
    //   })

    strikeRef.current = draw
      .line([
        [10, 10],
        [50, 50],
      ])
      .stroke({ width: strokeWidth })
      .attr({
        stroke: '#FFF',
        fill: 'none',
        'stroke-linecap': 'round butt',
        'vector-effect': 'non-scaling-stroke',
      })
  }, [])

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    if (!svgRef.current) {
      return
    }

    if (playerState.isMuted) {
      strikeRef.current.animate(speed).plot([
        [10, 10],
        [50, 50],
      ])
    } else {
      strikeRef.current.animate(speed).plot([
        [10, 10],
        [10, 10],
      ])
    }
  }, [playerState.isMuted])

  return (
    <Button
      onClick={() => {
        if (playerState.isMuted) {
          playerState.unmute()
        } else {
          playerState.mute()
        }
      }}
      sx={{ bg: 'transparent', p: 0, cursor: 'pointer' }}
      {...rest}
      ref={containerRef}
    ></Button>
  )
})

export default MuteButton
