import { useThemeUI } from 'theme-ui'
import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Rpath from 'ramda/src/path'
import RpathOr from 'ramda/src/pathOr'
import { motion } from 'framer-motion'
import { forceCheck } from 'react-lazyload'

// Components
import { Column, HalfColumn } from '@/components/Column'
import Grid from '@/components/Grid'
import ProjectsGrid from '@/components/ProjectsGrid'
import Section from '@/components/Section'
import { LargeHeaderWithLine } from '@/components/Header'
import withPageTransition from '@/components/withPageTransition'

const parseFeaturedWork = (data) =>
  RpathOr([], ['contentfulProjectList', 'projects'], data).map((entry) => {
    // const data = RpathOr({}, ['node'], entry)
    const featuredImage = Rpath(['featuredImage', 'localFile'], entry)
    // const slug = Rpath(['childMdx', 'fields', 'slug'], entry)
    return {
      ...entry,
      featuredImage,
    }
  })

const WorkIndex = ({ data }) => {
  const featuredWork = parseFeaturedWork(data)

  const { theme } = useThemeUI()

  return (
    <div sx={{ backgroundColor: 'lightGray' }}>
      <Helmet>
        <title>Work - Line Break</title>
      </Helmet>

      <Section offset>
        <Grid>
          <HalfColumn>
            <motion.div
              key="project-meta"
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{
                // ease: 'easeInOut',
                duration: theme.timings.pageTransition,
              }}
              onAnimationComplete={() => {
                forceCheck()
              }}
            >
              <LargeHeaderWithLine
                interpolation="basis"
                sx={{ color: 'mediumGray' }}
              >
                Work
              </LargeHeaderWithLine>
            </motion.div>
          </HalfColumn>
        </Grid>
        <ProjectsGrid projects={featuredWork} sx={{ mb: [4] }} />
      </Section>
    </div>
  )
}

// export default WorkIndex
export default withPageTransition(WorkIndex, 'work-index-page')

export const query = graphql`
  {
    contentfulProjectList(name: { eq: "featured" }) {
      projects {
        id
        title
        client
        slug
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
        }
        content {
          childMdx {
            body
          }
        }
      }
    }
  }
`
