import { Box, Flex, useThemeUI } from 'theme-ui'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Rpath from 'ramda/src/path'
import RpathOr from 'ramda/src/pathOr'
import { AnimatePresence, motion, LayoutGroup } from 'framer-motion'

import Minus from '@/components/Svg/Minus'

export const ReadMoreLess = ({ readMore = false, onClick = () => {} }) => {
  return (
    <button
      sx={{
        bg: 'transparent',
        boxSizing: 'content-box',
        outline: 'none',
        border: 'none',
        borderRadius: 'none',
        fontSize: 1,
        fontFamily: 'heading',
        color: 'mediumGray',
        p: 0,
        cursor: 'pointer',
        borderBottom: '2px solid',
        borderColor: 'transparent',

        '&:hover': {
          borderColor: 'mediumGray',
        },
      }}
      onClick={() => onClick()}
    >
      <Flex sx={{ alignItems: 'center', gap: 1, overflow: 'hidden' }}>
        <motion.div
          sx={{
            position: 'relative',
            width: '100px',
            lineHeight: 0,
            height: '100%',
            display: 'inline-block',
          }}
          layout
        >
          <AnimatePresence initial={false}>
            {readMore && (
              <motion.div
                key="read-less"
                initial={{ x: '-100%' }}
                animate={{
                  x: '0%',
                }}
                exit={{ x: '-100%' }}
                transition={{
                  ease: 'easeInOut',
                  duration: 0.5,
                }}
                sx={{
                  bg: 'cyan',
                  display: 'block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              >
                Read less
              </motion.div>
            )}
            {!readMore && (
              <motion.div
                key="read-more"
                initial={{ x: '-100%' }}
                animate={{
                  x: '0%',
                }}
                exit={{ x: '-100%' }}
                transition={{
                  ease: 'easeInOut',
                  duration: 0.5,
                }}
                sx={{
                  bg: 'magenta',
                  display: 'block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              >
                Read more
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        <motion.div layout>
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <motion.div
              sx={{ position: 'relative' }}
              animate={{
                // rotate: readMore ? '-90deg' : '0deg',
                scaleX: readMore ? 0 : 1,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 0.5,
              }}
            >
              <Minus
                sx={{
                  width: '32px',
                  height: 'auto',
                  fill: 'mediumGray',
                }}
              />
            </motion.div>

            <motion.div
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              animate={{
                rotate: readMore ? '0deg' : '90deg',
              }}
              transition={{
                ease: 'easeInOut',
                duration: 0.5,
              }}
            >
              <Minus
                sx={{
                  width: '32px',
                  height: 'auto',
                  fill: 'mediumGray',
                }}
              />
            </motion.div>
          </Box>
        </motion.div>
      </Flex>
    </button>
  )
}

export default ReadMoreLess
