import React from 'react'

const Paragraph = (props) => {
  return (
    <p
      sx={{
        fontFamily: 'body',
        fontSize: [0, 1, 2],
        lineHeight: 1.5,
        mt: 0,
        mb: 2,
        p: 0,
      }}
      {...props}
    />
  )
}

export default Paragraph
