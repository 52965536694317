import { useMemo } from 'react'
import { motion } from 'framer-motion'

import { computeCaretPath } from '@/helpers/svg'

const Caret = ({ width, height, strokeWidth, color }) => {
  const caretPath = useMemo(
    () => computeCaretPath(width, height, strokeWidth),
    [width, height]
  )

  return (
    <motion.svg sx={{ width: width, height: height }}>
      <motion.path
        d={caretPath}
        sx={{
          strokeWidth: strokeWidth,
          stroke: color,
          fill: 'none',
        }}
      />
    </motion.svg>
  )
}

export default Caret
