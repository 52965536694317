import { Box, Flex, useThemeUI, jsx } from 'theme-ui'
import React, { useEffect, useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { useLocation, useMatch } from '@reach/router'
import { motion, AnimatePresence } from 'framer-motion'

import { observer } from 'mobx-react'

const NavbarLink = observer(({ colorOverride, title, to, ...rest }) => {
  const [state, setState] = useState({ isHovered: false })

  const isActive = !!useMatch(`${to}/*`)
  const isHome = !!useMatch(`/`)

  const color = isHome ? 'white' : 'mediumGray'


  return (
    <Box
      sx={{
        display: 'inline-block',
        position: 'relative',
      }}
      onMouseEnter={() => setState({ isHovered: true })}
      onMouseLeave={() => setState({ isHovered: false })}
    >
      <Box
        sx={{
          position: 'relative',
          top: 0,
          height: '3px',
          // bg: 'yellow',
          left: 0,
          width: '100%',
          lineHeight: 0,
        }}
      >
        <AnimatePresence>
          {(state.isHovered || isActive) && (
            <motion.div
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              exit={{ scaleX: 0 }}
              sx={{
                width: '100%',
                borderTop: '3px solid',
                borderTopColor: colorOverride || color,
              }}
            />
          )}
        </AnimatePresence>
      </Box>
      <GatsbyLink
        aria-label={title}
        to={to}
        sx={{
          textDecoration: 'none',
          color: colorOverride || color,
          '&:visited': {
            color: colorOverride || color,
          },
          '&:hover': {
            textDecoration: 'none',
            color: colorOverride || color,
          },
          fontSize: [2, 3, 4],
          fontFamily: 'heading',
        }}
        {...rest}
      >
        {title}
      </GatsbyLink>
    </Box>
  )
})

export default NavbarLink
