import { useEffect, useRef, useState } from 'react'
import { Box } from 'theme-ui'
import { observer, useLocalObservable } from 'mobx-react'
import { motion } from 'framer-motion'

import Hls from 'hls.js'
import PlayerState from './PlayerState'
import VideoControls from './VideoControls'

const HlsVideo = observer(({ controls = false, src, ...rest }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [hlsSupported, setHlsSupported] = useState(true)
  const videoRef = useRef()

  const playerState = useLocalObservable(() => new PlayerState())

  /**
   * Bootstrap
   */
  useEffect(() => {
    return () => {
      playerState.teardown()
    }
  }, [])

  useEffect(() => {
    if (Hls.isSupported()) {
      playerState.init(videoRef, src)
    } else {
      setHlsSupported(false)
    }
  }, [])

  if (!hlsSupported) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        lineHeight: 0,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <video
        loop
        muted
        sx={{
          width: '100%',
          height: 'auto',
        }}
        ref={videoRef}
        {...rest}
      />
      <motion.div
        sx={{
          display: controls ? 'inline-block' : 'none',
          position: 'absolute',
          width: '100%',
          bottom: 0,
          left: 0,
          height: '60px',
        }}
        transition={{
          duration: 0.1,
          ease: 'easeInOut',
        }}
        animate={{ translateY: isHovered ? '0%' : '100%' }}
      >
        <VideoControls
          playerState={playerState}
          sx={{ position: 'absolute', width: '100%', bottom: 0, left: 0 }}
        />
      </motion.div>
    </Box>
  )
})

export default HlsVideo
