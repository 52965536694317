import { Box, useThemeUI, jsx } from 'theme-ui'
import { withSize as _withSize } from 'react-sizeme'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import useSize from '@react-hook/size'
import { motion, useAnimation } from 'framer-motion'

import { computeLinePath } from '@/helpers/svg'

const strokeWidth = 3

const Line = ({ interpolation = 'linear', strokeColor, height, ...rest }) => {
  const sizeRef = useRef()
  const [w, h] = useSize(sizeRef)

  const { theme } = useThemeUI()

  const [state, setState] = useState({
    isBootstrapped: false,
  })

  useEffect(() => {
    const validWidth = w > 0
    const validHeight = h > 0
    const validDimensions = validWidth && validHeight
    if (!validDimensions) {
      return
    }
  }, [w, h])

  const pathData = useMemo(
    () => computeLinePath(w, h, strokeWidth, interpolation),
    [w, h]
  )

  return (
    <Box
      ref={sizeRef}
      sx={{
        height: `${height}px`,
      }}
      {...rest}
    >
      <motion.svg sx={{ width: '100%', height: '100%' }}>
        <motion.path
          sx={{
            strokeWidth,
            stroke: strokeColor || theme.colors.mediumGray,
            fill: 'none',
          }}
          d={pathData}
        />
      </motion.svg>
    </Box>
  )
}

export default Line
