import React from 'react'
import { Box } from 'theme-ui'

const Column = ({ width = ['100%'], children, ...rest }) => (
  <Box
    mb={4}
    sx={{
      position: 'relative',
      px: [1, 1],
      width: width,
    }}
    {...rest}
  >
    {children}
  </Box>
)

const HalfColumn = ({ sx = {}, ...rest }) => (
  <Column sx={sx} {...rest} width={['100%', '100%', '50%']} />
)

export { Column, HalfColumn }
