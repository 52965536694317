import React, { useState } from 'react'
import { Box } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

import AspectRatio from '@/components/AspectRatio'
import { SmallHeader, SmallHeaderLight } from '@/components/Header'

const ProjectCard = React.forwardRef(
  (
    {
      aspectRatio = 1,
      id,
      client = '',
      featuredImage,
      onSelect = () => {},
      title = '',
      url,
      ...rest
    },
    ref
  ) => {
    const [isHovered, setIsHovered] = useState(false)

    return (
      <motion.div
        animate={{
          y: isHovered ? '-2%' : '0',
        }}
        transition={{
          ease: 'easeInOut',
          duration: 0.4,
        }}
        sx={{}}
        ref={ref}
        {...rest}
      >
        <Link
          sx={{ textDecoration: 'none' }}
          aria-label={`Project - ${title}`}
          to={url}
          onClick={() => {
            onSelect()
          }}
          onMouseOver={() => {
            setIsHovered(true)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
          }}
          state={{ from: url }}
        >
          <AspectRatio aspectRatio={aspectRatio} sx={{ overflow: 'hidden' }}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                overflow: 'hidden',
                // transform: isHovered
                //   ? 'scale3d(1.05, 1.05, 1)'
                //   : 'scale3d(1, 1, 1)',
                transition: 'all 200ms ease-in-out',
              }}
            >
              <GatsbyImage
                image={featuredImage.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
                alt=""
                sx={{
                  '[data-placeholder-image]': {
                    filter: 'blur(10px)',
                    transform: 'scale3d(1.05, 1.05, 1)',
                  },
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                  '.gatsby-image-wrapper': {},
                }}
              />
            </Box>
          </AspectRatio>
          <h2
            sx={{
              mt: '0.5rem',
              color: isHovered ? 'darkGray' : 'mediumGray',
              textDecoration: isHovered ? 'underline' : 'none',
              mb: '0.1rem',
            }}
          >
            {title}
          </h2>
          <h2
            sx={{
              mt: 0,
              color: isHovered ? 'darkGray' : 'gray',
              textDecoration: isHovered ? 'underline' : 'none',
            }}
          >
            {client}
          </h2>
        </Link>
      </motion.div>
    )
  }
)

export default ProjectCard
