import React from 'react'

const A = ({ children = null, ...rest }) => (
  <a
    sx={{
      cursor: 'pointer',
      fontFamily: 'body',
      lineHeight: 1.5,
      mt: 0,
      mb: 2,
      p: 0,
    }}
    {...rest}
  >
    {children}
  </a>
)

export default A
