import React from 'react'

const Logomark = React.forwardRef((props, ref) => {
  return (
    <svg data-name="Layer 1" viewBox="0 0 334.7 467.8" ref={ref} {...props}>
      <g>
        <path
          d="M229.9,418.7H164.6V407.8h0V301.6h0V208.3h0V101.5h-.1V32.3a10.22,10.22,0,0,0-10.1-10.2H99A10.93,10.93,0,0,0,88.1,33V479A10.93,10.93,0,0,0,99,489.9H229.5a10.07,10.07,0,0,0,10.1-10.1V428.9a10,10,0,0,0-9.7-10.2"
          transform="translate(-88.1 -22.1)"
          fill="#fff"
        />
        <path
          d="M362.2,248a10.92,10.92,0,0,1-2.2-17.3c20-19.2,31.2-45.3,31.2-80.8,0-69.2-52.7-127.8-137.7-127.8H192a10.24,10.24,0,0,0-10.2,10.2V83.6a10.1,10.1,0,0,0,5.5,9h56.4c46.8,0,70.5,29,70.5,63.2,0,34.6-22.4,61.7-66.6,63.2a10.28,10.28,0,0,0-8.3,10v51.5a10.23,10.23,0,0,0,9.8,10.2h18.2c52.7,0,78.4,23.2,78.4,61.4s-25.7,66.5-78.4,66.5h-.8a10.06,10.06,0,0,0-9.7,10.1v50.9a10.07,10.07,0,0,0,10.1,10.1h.4c90.2,0,155.5-52.7,155.5-134.4,0-51.1-24.8-87-60.6-107.3"
          transform="translate(-88.1 -22.1)"
          fill="#fff"
        />
      </g>
    </svg>
  )
})

export default Logomark
