import { useState, useEffect } from 'react'
import { Box, Button, Flex } from 'theme-ui'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'
import { autorun, observable, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { now } from 'mobx-utils'

// Components
import AnimatedLine from '@/components/AnimatedLine'

import GalleryItem from '@/components/GalleryItem'
import GalleryNavButton from '@/components/GalleryNavButton'

import Arrow from '@/components/Arrow'

const speed = 5000

// Animation config
const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? '100%' : '-100%',
      // opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    // opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '100%' : '-100%',
      // opacity: 0,
    }
  },
}

const Gallery = observer(({ items = [], ...rest }) => {
  // console.log('gallery items:', items)

  const state = useLocalObservable(() => ({
    page: 0,
    direction: 1,
    hasInteracted: false,
  }))

  // const [[page, direction], setPage] = useState([0, 0])
  const [hasInteracted, setHasInteracted] = useState(false)

  const slideIndex = wrap(0, items.length, state.page)

  const paginate = (newDirection) => {
    // setPage([page + newDirection, newDirection])
    runInAction(() => {
      state.page = state.page + newDirection
      state.direction = newDirection
    })
  }

  const setInteractionFlag = () => {
    runInAction(() => {
      state.hasInteracted = true
    })
  }

  useEffect(() => {
    const cancel = autorun(async () => {
      if (state.hasInteracted) {
        return
      }
      const _ = now(speed)

      paginate(1)
    })

    return cancel
  }, [])

  return (
    <Box
      {...rest}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',

        overflow: 'hidden',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: '-8px', // compensate for svg
          width: '100%',

          height: '60px',
          zIndex: 9,
          justifyContent: 'space-between',
          mb: 1,
          px: 3,
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            p: 0,
            bg: 'transparent',
            cursor: 'pointer',
            transform: 'rotate(180deg)',
          }}
          onClick={() => {
            paginate(1)

            setInteractionFlag()
          }}
        >
          <Arrow strokeWidth={2.5} sx={{ width: '44px', height: '44px' }} />
        </Button>

        {/* <Box sx={{ flex: 1, px: 1 }}>
          <Flex sx={{ width: '100%', gap: 1 }}>
            {items.map((item, ii) => {
              return (
                <Box
                  key={ii}
                  sx={{
                    flex: 1,
                    textAlign: 'center',
                    bg: 'white',
                    height: '2.5px',
                  }}
                ></Box>
              )
            })}
          </Flex>
        </Box> */}

        <Button
          sx={{
            p: 0,
            bg: 'transparent',
            cursor: 'pointer',
          }}
          onClick={() => {
            paginate(1)

            setInteractionFlag()
          }}
        >
          <Arrow strokeWidth={2.5} sx={{ width: '44px', height: '44px' }} />
        </Button>
      </Flex>

      <AnimatePresence initial={false} custom={state.direction}>
        <motion.div
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          key={state.page}
          custom={state.direction}
          transition={{
            duration: 0.4,
            ease: 'easeInOut',
          }}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <GalleryItem item={items[slideIndex]} isActive={true} />
        </motion.div>
      </AnimatePresence>

      {/* <motion.div
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          // transform: `translate3d(${-100 * slideIndex}%, 0, 0)`,
        }}
        animate={{
          x: `${-100 * slideIndex}%`,
        }}
        transition={{
          type: 'ease-in-out',
          duration: 0.4,
        }}
        onAnimationStart={() => {
          // console.log('Animation starting')
        }}
        onAnimationComplete={(definition) => {
          // console.log('Completed animating', definition)
        }}
      >
        {items.map((item, ii) => {
          const isActive = ii === slideIndex
          return (
            <motion.div
              key={item.id}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                transform: `translate3d(${100 * ii}%, 0, 0)`,
              }}
            >
              <GalleryItem item={item} isActive={isActive} />
            </motion.div>
          )
        })}
      </motion.div> */}
    </Box>
  )
})

export default Gallery
