// Let eslint know we are running in browser
/* eslint-env browser */

import React from 'react'

const GA_ID = 'UA-119165336-3'

const isProduction = process.env.NODE_ENV !== 'development'

let ReactGA = null

import('react-ga').then((ga) => {
  if (isProduction) {
    ga.initialize(GA_ID)
    ReactGA = ga
  }
})

// Polyfill intersection observer
if ('IntersectionObserver' in window) {
  // supported
} else {
  // not supported
  require('intersection-observer') // eslint-disable-line global-require
}

// setConfig({ pureSFC: true })

// // eslint-disable-next-line import/prefer-default-export
// const wrapRootElement = ({ element = null }) => (
//   <AppProvider>{element}</AppProvider>
// )

const onRouteUpdate = ({ location }) => {
  if (isProduction && !!ReactGA) {
    ReactGA.pageview(location.pathname)
  }

  if (
    location.pathname === '/journal' ||
    location.pathname.includes('/work/')
  ) {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0)
    }
  } else {
    window.onbeforeunload = () => {}
  }
}
export { onRouteUpdate }
