import { Box, useThemeUI } from 'theme-ui'
import React from 'react'
import { Link } from 'gatsby'

import Logomark from '@/components/Logomark'
import { MediumHeader } from '@/components/Header'
import P from '@/components/P'

const NotFoundPage = () => {
  const { theme } = useThemeUI()

  return (
    <Box
      sx={{
        backgroundColor: 'blue',
        height: '100vh',
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 999999999,
      }}
    >
      <Box px={4} py={4}>
        <Link
          to="/"
          css={{
            display: 'inline-block',
          }}
        >
          <Logomark css={{ height: '60px' }} />
        </Link>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate3d(-50%, -50%, 0)',
          fontSize: '25vw',
          color: 'white',
        }}
      >
        404
      </Box>
      <Box
        px={4}
        pb={4}
        css={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <MediumHeader sx={{ color: 'white' }}>404 Error</MediumHeader>
        <P sx={{ color: 'white' }}>
          The page you’re looking for can’t be found.
        </P>
      </Box>
    </Box>
  )
}

export default NotFoundPage
