import React from 'react'
import { motion } from 'framer-motion'
import { useThemeUI } from 'theme-ui'

const withPageTransition = (BaseComponent, key = null) => (props) => {
  const { theme } = useThemeUI()

  return (
    <motion.div
      style={{ top: 0, left: 0, width: '100%', minHeight: '100vh' }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
        position: 'absolute',
        transition: {
          duration: theme.timings.pageTransition,
        },
      }}
      transition={{
        duration: theme.timings.pageTransition,
      }}
      key={key}
    >
      <BaseComponent {...props} />
    </motion.div>
  )
}

export default withPageTransition
