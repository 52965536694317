import AppStore from './app'

class RootStore {
  appStore

  constructor() {
    this.appStore = new AppStore(this)
  }
}

export default RootStore
