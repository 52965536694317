import { Box, Flex, useThemeUI } from 'theme-ui'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useState, useEffect } from 'react'
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Rpath from 'ramda/src/path'
import RpathOr from 'ramda/src/pathOr'
import { motion } from 'framer-motion'

// Components
import { Column, HalfColumn } from '@/components/Column'
import Grid from '@/components/Grid'
import { LargeHeaderWithLine, SmallHeader } from '@/components/Header'
import P from '@/components/P'
import ProjectsNav from '@/components/ProjectsNav'
import Section from '@/components/Section'
import withPageTransition from '@/components/withPageTransition'

import ReadMoreLess from '@/components/ReadMoreLess'

const ProjectTemplate = withPageTransition(({ data }) => {
  const { theme } = useThemeUI()

  const project = RpathOr({}, ['project'], data)
  const featuredImage = Rpath(['featuredImage', 'localFile'], project)

  const [readMore, setReadMore] = useState(false)

  const body = RpathOr(null, ['content', 'childMdx', 'body'], project)

  const storyBody = RpathOr(null, ['story', 'childMdx', 'body'], project)

  const { client, summary, title, story, id } = project

  const featuredImageSrc = getSrc(data.project.featuredImage.localFile)

  const featuredImageUrl = `https://www.linebreak.studio/${featuredImageSrc}`

  const seoTitle = `${client}: ${title} - Line Break`

  const allProjects = RpathOr([], ['featured', 'projects'], data)

  const currentIndex = allProjects.findIndex((pp) => pp.id === project.id)

  useEffect(() => {}, [])

  return (
    <Box sx={{}}>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={summary} />
        <meta name="image" content={featuredImageUrl} />

        {/* OpenGraph tags */}
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={summary} />
        <meta property="og:image" content={featuredImageUrl} />

        {/* Twitter Card tags */}
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={summary} />
        <meta name="twitter:image" content={featuredImageUrl} />
      </Helmet>
      <Section offset>
        <Grid>
          <HalfColumn>
            <motion.div
              key="project-meta"
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{
                // ease: 'easeInOut',
                duration: theme.timings.pageTransition,
              }}
            >
              <LargeHeaderWithLine interpolation="basis" sx={{ mb: 0 }}>
                {title}
              </LargeHeaderWithLine>
              <motion.div
                initial={{ opacity: 0.0 }}
                animate={{ opacity: 1.0 }}
                exit={{ opacity: 0.0 }}
                transition={{
                  // ease: 'easeInOut',
                  delay: theme.timings.pageTransition * 0.5,
                  duration: theme.timings.textTransition,
                }}
              >
                <SmallHeader sx={{ color: 'gray' }}>{client}</SmallHeader>
              </motion.div>
            </motion.div>
          </HalfColumn>
          <HalfColumn>
            <motion.div
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              exit={{ opacity: 0.0 }}
              transition={{
                // ease: 'easeInOut',
                delay:
                  theme.timings.pageTransition * 0.5 +
                  theme.timings.textTransition * 0.5,
                duration: theme.timings.textTransition,
              }}
            >
              <p
                sx={{ m: 0, maxWidth: '960px', fontSize: [2, 3, 3, 4] }}
                // sx={{ fontFamily: 'heading' }}
                // sx={{ mt: [0, '30px'] }}
                dangerouslySetInnerHTML={{ __html: summary }}
              />
            </motion.div>
          </HalfColumn>
        </Grid>
      </Section>

      <Section>
        <Grid>
          <Column>
            <motion.div
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{
                // ease: 'easeInOut',
                delay:
                  theme.timings.pageTransition * 0.5 +
                  theme.timings.textTransition * 1,
                duration: theme.timings.textTransition,
              }}
            >
              <ReadMoreLess
                onClick={() => {
                  setReadMore(!readMore)
                }}
                readMore={readMore}
              />
            </motion.div>

            {readMore && (
              <motion.div sx={{ fontSize: 2 }} layout>
                {storyBody && (
                  <Grid sx={{ mx: 0, mt: 3 }}>
                    <MDXRenderer>{storyBody}</MDXRenderer>
                  </Grid>
                )}
              </motion.div>
            )}
          </Column>
        </Grid>
      </Section>
      <motion.div layout>
        <Section>
          <Grid>
            <Column>
              <motion.div
                initial={{ opacity: 0.0 }}
                animate={{ opacity: 1.0 }}
                exit={{ opacity: 0.0 }}
                transition={{
                  // ease: 'easeInOut',
                  delay:
                    theme.timings.pageTransition * 0.5 +
                    theme.timings.textTransition * 2 * 0.5,
                  duration: theme.timings.textTransition,
                }}
              >
                <GatsbyImage
                  image={featuredImage.childImageSharp.gatsbyImageData}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                  loading="lazy"
                  // placeholderStyle={{
                  //   filter: 'blur(1vw)',
                  // }}
                  css={{
                    width: '100%',
                    height: 'auto',
                    // marginTop: theme.sizes.nav,
                  }}
                />
              </motion.div>
            </Column>
          </Grid>
        </Section>
        <Section>
          {body && (
            <Grid>
              <MDXRenderer>{body}</MDXRenderer>
            </Grid>
          )}
        </Section>
        <Section offset>
          <ProjectsNav currentIndex={currentIndex} allProjects={allProjects} />
        </Section>
      </motion.div>
    </Box>
  )
}, 'project-page')

// export default ProjectTemplate
export default ProjectTemplate

export const query = graphql`
  query($id: String!) {
    featured: contentfulProjectList(name: { eq: "featured" }) {
      projects {
        id
        title
        client
        slug
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        content {
          childMdx {
            body
          }
        }
      }
    }
    project: contentfulProject(id: { eq: $id }) {
      id
      title
      client
      summary
      featuredImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              formats: [AUTO, WEBP, AVIF]
              placeholder: BLURRED
              layout: FULL_WIDTH
            )
          }
        }
      }
      content {
        childMdx {
          body
        }
      }
      story {
        childMdx {
          body
        }
      }
    }
  }
`
