import { Box } from 'theme-ui'
import React from 'react'
import { graphql } from 'gatsby'
import Rpath from 'ramda/src/path'
import RpathOr from 'ramda/src/pathOr'
import { motion } from 'framer-motion'

// Components
import Gallery from '@/components/Gallery'
import ProjectsGrid from '@/components/ProjectsGrid'
import Section from '@/components/Section'
import withPageTransition from '@/components/withPageTransition'

const parseFeaturedWork = (data) =>
  RpathOr([], ['featuredProjects', 'projects'], data).map((entry) => {
    // const data = RpathOr({}, ['node'], entry)
    const featuredImage = Rpath(['featuredImage', 'localFile'], entry)
    // const slug = Rpath(['childMdx', 'fields', 'slug'], entry)
    return {
      ...entry,
      featuredImage,
    }
  })

const parseGallery = (data) =>
  RpathOr([], ['heroGallery', 'galleryItems'], data).map((entry) => {
    // const data = RpathOr({}, ['node'], entry)
    // const featuredImage = Rpath(['featuredImage', 'localFile'], entry)
    // const slug = Rpath(['childMdx', 'fields', 'slug'], entry)
    return {
      ...entry,
      // featuredImage,
    }
  })

const Index = ({ data }) => {
  const featuredWork = parseFeaturedWork(data)
  const heroGalleryItems = parseGallery(data)

  return (
    <>
      <Gallery
        sx={{
          width: '100%',
          height: '100vh',
        }}
        items={heroGalleryItems}
      />
      <Section>
        <ProjectsGrid projects={featuredWork} sx={{ mb: [4] }} />
      </Section>
    </>
  )
}

export default withPageTransition(Index, 'index-page')

export const query = graphql`
  {
    featuredProjects: contentfulProjectList(name: { eq: "featured" }) {
      projects {
        id
        title
        client
        slug
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        content {
          childMdx {
            body
          }
        }
      }
    }
    heroGallery: contentfulGallery(name: { eq: "hero" }) {
      galleryItems {
        id
        colorTheme
        project {
          title
          summary
          slug
          client
        }
        content {
          childMdx {
            body
          }
        }
      }
    }
  }
`
