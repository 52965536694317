import React from 'react'
import { Box } from 'theme-ui'

// Components
import { Column } from '@/components/Column'
import Grid from '@/components/Grid'

const columns = Array.from(Array(16), (_, x) => x)

const GridOverlay = ({ sx = {}, ...rest }) => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      zIndex: 'gridOverlay',
      pointerEvents: 'none',
    }}
  >
    <Grid
      sx={{
        height: '100%',
      }}
    >
      {columns.map((_, index) => {
        return (
          <Column
            width={`${(1 / 16) * 100}%`}
            sx={{
              height: '100%',
            }}
            key={index}
          >
            <Box
              sx={{
                background: 'cyan',
                width: '100%',
                height: '100%',
                opacity: 0.25,
              }}
            />
          </Column>
        )
      })}
    </Grid>
  </Box>
)

export default GridOverlay
