import { Box, useThemeUI } from 'theme-ui'
import { withSize as _withSize } from 'react-sizeme'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import useSize from '@react-hook/size'
import {
  motion,
  animate,
  useAnimation,
  useMotionValue,
  useTransform,
} from 'framer-motion'

// import { interpolate } from 'flubber'

import {
  computeLinePath,
  computeArrowPath,
  getLineLinksCount,
} from '@/helpers/svg'

import pctString from '@/helpers/pctString'

const Line = ({
  interpolation = 'linear',
  height,
  play = false,
  strokeColor = 'mediumGray',
  reverse = false,
  withArrowHead = false,
  strokeWidth = 2.5,
  speed = 20,
  ...rest
}) => {
  const sizeRef = useRef()
  const [w, h] = useSize(sizeRef)

  const usableH = h - strokeWidth / 2
  const triH = usableH
  const triW = usableH / Math.sqrt(2)

  const { theme } = useThemeUI()

  const controls = useAnimation()

  const [state, setState] = useState({
    isBootstrapped: false,
  })

  const x = useMotionValue(0)

  useEffect(() => {
    const validWidth = w > 0
    const validHeight = h > 0
    const validDimensions = validWidth && validHeight

    if (!validDimensions) {
      return
    }

    // if (play) {
    //   controls.start({
    //     x: '100%',
    //     transition: {
    //       duration: 10,
    //       repeat: Infinity,
    //       type: 'tween',
    //       ease: 'linear',
    //     },
    //   })
    // } else {
    //   controls.stop()
    // }

    // console.log(w, h)
  }, [w, h])

  useEffect(() => {
    let currentValueX = parseFloat(x.get()) / 100
    // x.onChange((v) => {
    //   if (parseFloat(v) === 100 && play === true) {
    //     console.log('@@@')
    //     x.set('0%')
    //     animate(x, '100%', {
    //       duration: speed * (1 - currentValue),
    //       type: 'tween',
    //       ease: 'linear',
    //       onUpdate: (progress) => {},
    //       onComplete: () => {
    //         console.log('***')
    //       },
    //     })
    //   }
    // })

    if (play) {
      // x.set('0%')
      // console.log(currentValue)
      currentValueX = currentValueX > 1 ? currentValueX - 1 : currentValueX
      x.set(pctString(currentValueX))
      const controls = animate(x, pctString(currentValueX + 1), {
        duration: speed,
        // duration: speed * (1 - currentValue),
        repeat: Infinity,
        type: 'tween',
        ease: 'linear',
        onUpdate: (progress) => {},
        // onComplete: () => {
        // },
      })

      return controls.stop
      // controls.start({
      //   x: '100%',
      //   backgroundColor: '#f00',
      //   transition: {
      //     duration: speed,
      //     repeat: Infinity,
      //     type: 'tween',
      //     ease: 'linear',
      //   },
      // })
    } else {
      // Animate to the next fractional phase
      const phaseTarget = (Math.ceil(currentValueX / (1 / 8)) * 1) / 8

      const distance = phaseTarget - currentValueX

      const controls = animate(x, pctString(phaseTarget), {
        duration: speed * distance,
        type: 'tween',
        ease: 'linear',
        onUpdate: (progress) => {},
      })

      return controls.stop
    }
  }, [play])

  const triY = useTransform(x, (xValue) => {
    const xValueFloat = parseFloat(xValue) / 100
    const offset = Math.sin(xValueFloat * 2 * Math.PI * 2) / 2
    return `${-offset * 100}%`
  })

  const triRotation = useTransform(x, (xValue) => {
    const xValueFloat = parseFloat(xValue) / 100
    const rotation = (Math.cos(xValueFloat * 4 * Math.PI) * 180) / Math.PI
    // console.log('rotation:', rotation)
    return rotation
  })

  const linePathData = useMemo(
    () => computeLinePath(w, h, strokeWidth, interpolation),
    [w, h]
  )

  const arrowPathData = useMemo(
    () => computeArrowPath(triW, triH, strokeWidth),
    [w, h]
  )

  // const lineLinks = getLineLinksCount(w, h, strokeWidth)
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'visible',
        transform: reverse ? 'rotate(180deg)' : 'none',
      }}
    >
      {/* <svg>
        <marker
          id="triangle"
          viewBox="0 0 10 10"
          refX="1"
          refY="5"
          markerUnits="strokeWidth"
          markerWidth="10"
          markerHeight="10"
          orient="auto"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill="#f00" />
        </marker>
      </svg> */}

      <motion.div
        sx={{
          overflow: 'visible',
          position: 'absolute',
          width: '20px',
          height: '20px',
          top: '50%',
          transform: 'translate3d(0, -50%, 0)',
          right: '-10px',
          // bg: 'red',
        }}
      >
        {/* <motion.div
          sx={{
            position: 'absolute',
            // width: '17.5px',
            // height: '17.5px',
            width: '20px',
            height: '20px',
            borderRadius: '10px',
            top: 0,
            left: 0,
            bg: 'blue',
            opacity: 0.5,
          }}
          style={{
            y: y,
            // rotate: rot,
            // transformOrigin: 'left center',
          }}
        /> */}

        {/* <motion.svg
          sx={{
            position: 'absolute',
            zIndex: -1,
            top: `${10 - triH / 2}px`,
            right: `${10 - triW / 2}px`,
            overflow: 'visible',
            width: triW,
            height: triH,
            // backgroundColor: 'orange',
            opacity: 1,
          }}
          style={{
            originX: '50%',
            originY: '50%',
            y: triY,
            //rotate: triRotation,
            transformOrigin: 'center',
          }}
        >
          <motion.path
            sx={{
              strokeWidth,
              stroke: strokeColor,
              strokeLinecap: 'square',
              // fill: 'none',
              fill: strokeColor,
              // transform: `translate3d(-${w}px, 0, 0)`,
              // transformOrigin: 'center',
            }}
            d={arrowPathData}
          />
        </motion.svg> */}
      </motion.div>

      <Box
        ref={sizeRef}
        sx={{
          position: 'relative',
          height: `${height}px`,
          overflowX: 'hidden',
          overflowY: 'visible',
        }}
        {...rest}
      >
        <motion.svg
          // animate={{
          //   // x: x,
          //   // x: play ? '100%' : '0',
          //   transition: {
          //     duration: play ? 2 : 2,
          //     repeat: play ? Infinity : 0,
          //     type: 'tween',
          //     // type: play ? 'tween' : 'spring',
          //     ease: play ? 'linear' : 'easeOut',
          //     // bounce: 0.2,
          //   },
          // }}
          // animate={controls}
          style={{
            x: x,
          }}
          sx={{
            width: '100%',
            height: height,
            overflow: 'visible',
          }}
        >
          <motion.path
            sx={{
              strokeWidth,
              stroke: strokeColor,
              fill: 'none',
              overflow: 'visible',
            }}
            // markerEnd="url(#triangle)"
            d={linePathData}
          />
          <motion.path
            sx={{
              strokeWidth,
              stroke: strokeColor,
              strokeLinecap: 'square',
              fill: 'none',
              overflow: 'visible',
              transform: `translate3d(-${w}px, 0, 0)`,
              // transformOrigin: 'center',
            }}
            d={linePathData}
          />
          <motion.path
            sx={{
              strokeWidth,
              stroke: strokeColor,
              strokeLinecap: 'square',
              fill: 'none',
              overflow: 'visible',
              transform: `translate3d(-${w * 2}px, 0, 0)`,
              // transformOrigin: 'center',
            }}
            d={linePathData}
          />
        </motion.svg>
      </Box>
    </Box>
  )
}

export default Line
