const Twitter = (props) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M11.9,24.9c7,0,12.8-5.6,12.8-12.7c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4,0-0.6c0.9-0.6,1.6-1.4,2.2-2.3c-0.8,0.4-1.7,0.6-2.6,0.7
	c0.9-0.6,1.7-1.5,2-2.5c-0.9,0.5-1.9,0.9-2.9,1.1c-1.7-1.8-4.6-1.9-6.4-0.2c-1.2,1.1-1.7,2.7-1.3,4.3c-3.6-0.2-7-1.9-9.3-4.7
	c-1.2,2.1-0.6,4.7,1.4,6c-0.7,0-1.4-0.2-2-0.6v0.1c0,2.1,1.5,4,3.6,4.4c-0.7,0.2-1.3,0.2-2,0.1c0.6,1.8,2.3,3,4.2,3.1
	c-1.6,1.3-3.6,2-5.6,2c-0.4,0-0.7,0-1.1-0.1C7.1,24.2,9.5,24.9,11.9,24.9"
    />
  </svg>
)

export default Twitter
