import { observer } from 'mobx-react'
import { Box, Flex } from 'theme-ui'
import ScrollLock from 'react-scrolllock'
import { useState } from 'react'

import { motion, AnimatePresence } from 'framer-motion'
import AnimatedLine from '@/components/AnimatedLine'

import { useStores } from '@/stores'

import { Link } from 'gatsby'
import Burger from './Burger'

const speed = 0.25

const MenuItem = ({ to, children, interpolation = 'linear' }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <Link
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        to={to}
        sx={{
          color: '#fff !important',
          textDecoration: 'none',
          fontFamily: 'heading',
        }}
      >
        {children}
      </Link>
      {isHovered && (
        <AnimatedLine
          speed={4}
          interpolation={interpolation}
          play
          strokeColor="white"
          strokeWidth={4}
          sx={{
            position: 'absolute',
            bottom: '-1.25rem',
            width: '100%',
            height: '20px',
          }}
        />
      )}
    </Box>
  )
}

const Menu = observer(() => {
  const { appStore } = useStores()

  return (
    <>
      <ScrollLock isActive={appStore.isMenuOpen}></ScrollLock>
      <motion.div
        sx={{
          width: '100vw',
          height: '100vh',
          bg: 'blue',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 'menu',
        }}
        initial={{ translateY: '-100%' }}
        animate={{ translateY: appStore.isMenuOpen ? '0%' : '-100%' }}
        transition={{ ease: 'easeInOut', duration: speed }}
      >
        <Flex
          sx={{
            px: 1,
            alignItems: 'center',
            height: '72px',
            // justifyContent: 'flex-end',
          }}
        >
          <Burger isOpen />
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            ml: 3,
            position: 'absolute',
            top: '50%',
            width: '100%',
            transform: 'translate3d(0, -50%, 0)',
            mb: 3,
            left: 0,
            gap: 0,
            fontSize: ['12vh'],
            lineHeight: ['12vh'],
            textAlign: 'left',
          }}
        >
          <AnimatePresence>
            {appStore.isMenuOpen && (
              <>
                <motion.div
                  sx={{ pr: 3, mb: 3 }}
                  initial={{
                    translateX: '-100%',
                  }}
                  animate={{ translateX: '0%' }}
                  transition={{
                    ease: 'easeInOut',
                    duration: speed,
                    delay: speed,
                  }}
                >
                  <MenuItem interpolation="basis" to="/work">
                    Work
                  </MenuItem>
                </motion.div>
                {/* <motion.div
                  sx={{ pr: 3 }}
                  initial={{
                    translateX: '-100%',
                  }}
                  animate={{ translateX: '0%' }}
                  transition={{
                    ease: 'easeInOut',
                    duration: speed,
                    delay: speed * 1.5,
                  }}
                >
                  <Link
                    to="/news"
                    sx={{
                      color: '#fff !important',
                      textDecoration: 'none',

                      fontFamily: 'heading',
                      '&:hover': { textDecoration: 'underline' },
                    }}
                  >
                    News
                  </Link>
                </motion.div> */}
                <motion.div
                  sx={{ pr: 3 }}
                  initial={{
                    translateX: '-100%',
                  }}
                  animate={{ translateX: '0%' }}
                  transition={{
                    ease: 'easeInOut',
                    duration: 0.25,
                    delay: speed * 1.5,
                  }}
                >
                  <MenuItem to="/about">About</MenuItem>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </Flex>
      </motion.div>
    </>
  )
})

export default Menu
