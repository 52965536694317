import { Box, Button, Flex } from '@theme-ui/components'
import { Column, HalfColumn } from '@/components/Column'
import { wrap } from '@popmotion/popcorn'
import { useState } from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'

// Helpers
import pctString from '@/helpers/pctString'

// Components

import Grid from '@/components/Grid'
import { GatsbyImage } from 'gatsby-plugin-image'

import Arrow from './ControlledArrow'

const ProjectNavCard = ({ text, direction, project }) => {
  const [isHovered, setIsHovered] = useState(false)

  const rotation = direction > 0 ? 0 : 180
  const justify = direction > 0 ? 'flex-start' : 'flex-start'
  const flexDirection = direction > 0 ? 'row-reverse' : 'row'

  return (
    <Link
      sx={{
        color: 'mediumGray',
        textDecoration: 'none',
        '&:hover': {
          color: 'mediumGray',
        },
      }}
      to={`/work/${project.slug}`}
    >
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: justify,
            flexDirection,
            mb: 1,
          }}
        >
          <motion.div
            animate={{
              x: isHovered ? `${5 * direction}px` : 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 0.5,
              repeat: isHovered ? Infinity : 0,
              repeatType: 'mirror',
            }}
          >
            <Arrow
              isHovered={isHovered}
              color="mediumGray"
              strokeWidth={2.5}
              sx={{
                width: ['36px', '44px'],
                height: ['36px', '44px'],
                transform: `rotate(${rotation}deg)`,
              }}
            />
          </motion.div>
          <Box sx={{ fontSize: [2, 2, 3], fontFamily: 'heading' }}>{text}</Box>
        </Flex>
        {project.featuredImage && (
          <GatsbyImage
            sx={{ pointerEvents: 'none' }}
            image={
              project.featuredImage.localFile.childImageSharp.gatsbyImageData
            }
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
            css={{
              width: '100%',
              height: '100%',
            }}
          />
        )}
        <Box sx={{ mt: 1 }}>
          <Box
            sx={{
              fontSize: [1, 1, 2],
              fontFamily: 'heading',
              color: 'gray',
            }}
          >
            {project.client}
          </Box>
          <Box
            sx={{
              fontSize: [1, 1, 2],
              fontFamily: 'heading',
              color: 'gray',
            }}
          >
            {project.title}
          </Box>
        </Box>
      </Box>
    </Link>
  )
}

const ProjectsNav = ({ currentIndex, allProjects, ...rest }) => {
  const prevIndex = wrap(0, allProjects.length, currentIndex - 1)
  const nextIndex = wrap(0, allProjects.length, currentIndex + 1)

  const prevProject = allProjects[prevIndex]
  const nextProject = allProjects[nextIndex]

  return (
    <Box sx={{ pt: 4 }}>
      <Grid>
        <Column width={[pctString(8 / 8), pctString(4 / 8)]}>
          <ProjectNavCard
            project={prevProject}
            text="Previous Project"
            direction={-1}
          />
        </Column>
        <Column width={[pctString(8 / 8), pctString(4 / 8)]}>
          <ProjectNavCard
            project={nextProject}
            text="Next Project"
            direction={1}
          />
        </Column>
      </Grid>
    </Box>
  )
}

export default ProjectsNav
