import React from 'react'
import { Box, useThemeUI, jsx } from 'theme-ui'

// Components
import Line from '@/components/Line'

const Header = ({ ...rest }) => (
  <header
    sx={{
      fontFamily: 'heading',
    }}
    {...rest}
  />
)

const HeroHeader = ({ sx = {}, ...rest }) => (
  <Header
    {...rest}
    sx={{
      position: 'relative',
      fontFamily: 'heading',
      fontSize: [4, 5, 5, 6, 7],
      lineHeight: [1.25, 1.15, 1.15],
      // mt: 0,
      // mb: 1,
      p: 0,
      ...sx,
    }}
  />
)

const HeroHeaderWithLine = ({ sx = {}, ...rest }) => (
  <Box sx={sx}>
    <Box sx={{ height: '60px' }}>
      <Line height={60} />
    </Box>
    <HeroHeader {...rest} />
  </Box>
)

const LargeHeader = ({ ...props }) => (
  <Header
    {...props}
    sx={{
      fontSize: [4, 5, 6],
      lineHeight: 1.05,
      mb: 2,
    }}
  />
)

const LargeHeaderWithLine = ({
  children,
  interpolation = 'linear',
  ...rest
}) => (
  <Box sx={{ position: 'relative' }} {...rest}>
    {/* <Box sx={{ height: '15px' }}>
      <Line
        height={15}
        isInteractive
        interpolation={interpolation}
        links={5}
        // dashed
        offset={0}
        strokeColor={theme.colors.mediumGray}
        strokeWidth={3}
        sx={{
          position: 'absolute',
          width: '100%',
          left: 0,
          transform: 'translate3d(0, 15px, 0)',
          padding: 'inherit',
          overflow: 'visible',
        }}
      />
    </Box> */}
    <LargeHeader
      sx={{
        borderTop: '3px solid',
      }}
    >
      {children}
    </LargeHeader>
  </Box>
)

const MediumHeaderWithLine = ({
  children,
  interpolation = 'linear',
  ...rest
}) => (
  <Box sx={{ position: 'relative' }} {...rest}>
    {/* <Box sx={{ height: '15px' }}>
      <Line
        height={15}
        isInteractive
        interpolation={interpolation}
        links={5}
        // dashed
        offset={0}
        strokeColor={theme.colors.mediumGray}
        strokeWidth={3}
        sx={{
          position: 'absolute',
          width: '100%',
          left: 0,
          transform: 'translate3d(0, 15px, 0)',
          padding: 'inherit',
          overflow: 'visible',
        }}
      />
    </Box> */}
    <MediumHeader
      sx={{
        borderTop: '3px solid',
      }}
    >
      {children}
    </MediumHeader>
  </Box>
)

const SmallHeaderWithLine = ({ children, color, ...props }) => {
  const { theme } = useThemeUI()
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ height: '60px' }}>
        <Line
          height={20}
          isInteractive
          interpolation="basis"
          links={5}
          // dashed
          offset={0}
          strokeColor={color || theme.colors.mediumGray}
          strokeWidth={3}
          sx={{
            position: 'absolute',
            width: '100%',
            left: 0,
            transform: 'translate3d(0, 30px, 0)',
            padding: 'inherit',
            overflow: 'visible',
          }}
        />
      </Box>
      <SmallHeader
        sx={{
          pt: 1,
        }}
      >
        {children}
      </SmallHeader>
    </Box>
  )
}

const SmallHeaderWithLineLight = ({ children, color, ...props }) => {
  const { theme } = useThemeUI()
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ height: '12px' }}>
        <Line
          height={12}
          isInteractive
          interpolation="basis"
          // dashed
          links={5}
          offset={0}
          strokeColor={color || theme.colors.mediumGray}
          strokeWidth={3}
          css={{
            position: 'absolute',
            width: '100%',
            left: 0,
            transform: 'translate3d(0, 30px, 0)',
            padding: 'inherit',
            overflow: 'visible',
          }}
        />
      </Box>
      <SmallHeaderLight
        sx={{
          pt: 1,
        }}
      >
        {children}
      </SmallHeaderLight>
    </Box>
  )
}

const MediumHeader = ({ sx, ...rest }) => (
  <Header
    sx={{
      fontSize: [3, 3, 4, 5],
    }}
    {...rest}
  />
)

const SmallHeader = ({ sx, ...rest }) => (
  <Header
    {...rest}
    sx={{
      fontSize: [3, 3, 3, 3],
      ...sx,
    }}
  />
)

const SmallHeaderLight = ({ sx, ...rest }) => (
  <Header
    {...rest}
    sx={{
      fontSize: [3, 3, 3, 3],
      fontFamily: 'body',
      ...sx,
    }}
  />
)

export {
  Header,
  HeroHeader,
  HeroHeaderWithLine,
  LargeHeader,
  LargeHeaderWithLine,
  MediumHeader,
  MediumHeaderWithLine,
  SmallHeader,
  SmallHeaderWithLine,
  SmallHeaderLight,
  SmallHeaderWithLineLight,
}
