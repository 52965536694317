import { Box, ThemeProvider } from 'theme-ui'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AnimatePresence } from 'framer-motion'
import { MDXProvider } from '@mdx-js/react'

// Fonts
import './fonts.css'
import EuclidFlexRegularUrl from '@/fonts/EuclidFlex-Regular-WebXL.woff2'
import EuclidFlexMediumUrl from '@/fonts/EuclidFlex-Medium-WebXL.woff2'

// Components
import Caption from '@/components/Caption'
import { Column, HalfColumn } from '@/components/Column'
import Footer from '@/components/Footer'
import Grid from '@/components/Grid'
import GridOverlay from '@/components/GridOverlay'
import MdxImg from '@/components/MdxImg'
import Menu from '@/components/Menu'
import Navbar from '@/components/Navbar'
import P from '@/components/P'
import ScrollActivatedNavbar from '@/components/ScrollActivatedNavbar'
import Transition from '@/components/Transition'
import useSiteMetadata from '@/components/SiteMetadata'
import VimeoPlayer from '@/components/VimeoPlayer'
import ZoomImg from '@/components/ZoomImg'
import HlsVideo from '@/components/HlsVideo'

import * as theme from '@/theme'

import { useStores } from '@/stores'

const isDevelopment = process.env.NODE_ENV === 'development'
// const isBrowser = typeof window !== 'undefined'

// https://mdxjs.com/blog/shortcodes
const mdxShortcodes = {
  p: P,
  img: MdxImg,
  Caption,
  Column,
  HalfColumn,
  Grid,
  HlsVideo: (props) => <HlsVideo {...props} />,
  ZoomImg: (props) => <ZoomImg {...props} />,
  Vimeo: (props) => <VimeoPlayer {...props} />,
}

const TemplateWrapper = ({ location, ...rest }) => {
  const { title, twitterHandle, description, url, image } = useSiteMetadata()
  const { appStore } = useStores()

  const [state, setState] = useState({
    y: 0,
    direction: 1,
    isBelowFold: false,
  })

  const is404 = location.pathname === '/404' || location.pathname === '/404/'

  React.useEffect(() => {}, [])

  useEffect(() => {
    appStore.closeMenu()
  }, [location])

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        {/* {isBlogPost ? (
                <meta property="og:type" content="article" />
              ) : null} */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterHandle} />
        <meta name="twitter:creator" content={twitterHandle} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <link
          rel="preload"
          href={EuclidFlexRegularUrl}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href={EuclidFlexMediumUrl}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="canonical"
          href={`https://www.linebreak.studio${location.pathname}`}
        />
      </Helmet>
      <Box>
        {/* {isDevelopment && <GridOverlay />} */}

        {!is404 && <Menu />}
        {!is404 && (
          <>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 'nav',
              }}
            >
              <Navbar />
            </Box>
            <ScrollActivatedNavbar />
          </>
        )}

        <MDXProvider components={mdxShortcodes}>
          <Transition {...rest} />
        </MDXProvider>

        {!is404 && <Footer />}
      </Box>
    </ThemeProvider>
  )
}

export default TemplateWrapper
