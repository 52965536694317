import { Box, Flex } from 'theme-ui'
import { observer } from 'mobx-react'
import { transparentize } from '@theme-ui/color'

import MuteButton from './MuteButton'
import PlayPauseButton from './PlayPauseButton'
import Scrubber from './Scrubber'

const VideoControls = observer(({ playerState, ...rest }) => {
  return (
    <Flex
      sx={{
        height: '60px',
        width: '100%',
        bg: transparentize('darkGray', 0.3),
        // backdropFilter: 'blur(10px)',
      }}
      {...rest}
    >
      <PlayPauseButton
        playerState={playerState}
        sx={{ width: '60px', height: '60px' }}
        onClick={() => {
          if (playerState.isPlaying) {
            playerState.pause()
          } else {
            playerState.play()
          }
        }}
      />
      <Scrubber playerState={playerState} sx={{ flex: 1 }} />
      <MuteButton
        playerState={playerState}
        sx={{ width: '60px', height: '60px' }}
      />
    </Flex>
  )
})

export default VideoControls
