import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Box, Flex, Button, useThemeUI } from 'theme-ui'
import { motion } from 'framer-motion'
import { SVG, Line, Svg } from '@svgdotjs/svg.js'

import { observer } from 'mobx-react'

import { useStores } from '@/stores'

const speed = 100

const Burger = observer(({ isOpen = false, ...rest }) => {
  const { appStore } = useStores()

  const [isHovered, setIsHovered] = useState(false)

  const containerRef = useRef(null)

  const svgRef = useRef(null)
  const topRef = useRef(null)
  const middleRef = useRef(null)
  const bottomRef = useRef(null)

  useEffect(() => {
    if (!svgRef.current) {
      return
    }

    if (isOpen) {
      topRef.current.animate(speed).attr({
        x1: 15,
        y1: 15,
        x2: 45,
        y2: 45,
      })

      middleRef.current.animate(speed).attr({
        x1: 30,
        y1: 30,
        x2: 30,
        y2: 30,
      })

      bottomRef.current.animate(speed).attr({
        x1: 15,
        y1: 45,
        x2: 45,
        y2: 15,
      })

      // svgRef.current!.animate(speed).transform({
      //   rotate: -45,
      //   originX: 0,
      //   originY: 0,
      // })
    }

    if (!isOpen) {
      topRef.current.animate(speed).attr({
        x1: 10,
        y1: 20,
        x2: 50,
        y2: 20,
      })

      middleRef.current.animate(speed).attr({
        x1: 10,
        y1: 30,
        x2: 50,
        y2: 30,
      })

      bottomRef.current.animate(speed).attr({
        x1: 10,
        y1: 40,
        x2: 50,
        y2: 40,
      })

      svgRef.current.animate(speed).transform({
        rotate: 0,
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    if (svgRef.current) {
      return
    }

    const draw = SVG().addTo(containerRef.current).size(60, 60)

    if (isOpen) {
      topRef.current = draw
        .line(15, 15, 45, 45)
        .stroke({ width: 3 })
        .attr({ stroke: '#000', 'stroke-linecap': 'round' })

      middleRef.current = draw
        .line(30, 30, 30, 30)
        .stroke({ width: 3 })
        .attr({ stroke: '#000', 'stroke-linecap': 'round' })

      bottomRef.current = draw
        .line(15, 45, 45, 15)
        .stroke({ width: 3 })
        .attr({ stroke: '#000', 'stroke-linecap': 'round' })
    } else {
      topRef.current = draw
        .line(10, 20, 50, 20)
        .stroke({ width: 3 })
        .attr({ stroke: '#000', 'stroke-linecap': 'round' })

      middleRef.current = draw
        .line(10, 30, 50, 30)
        .stroke({ width: 3 })
        .attr({ stroke: '#000', 'stroke-linecap': 'round' })

      bottomRef.current = draw
        .line(10, 40, 50, 40)
        .stroke({ width: 3 })
        .attr({ stroke: '#000', 'stroke-linecap': 'round' })
    }

    svgRef.current = draw
  }, [])

  return (
    <Button
      variant="burger"
      sx={{
        svg: {
          pointerEvents: 'none',
          line: {
            stroke: '#fff',
          },
        },
      }}
      onClick={() => appStore.toggleMenu()}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      {...rest}
    >
      <motion.div
        animate={{
          scale: isHovered ? 1.15 : 1.0,
        }}
        transition={{
          ease: 'easeInOut',
        }}
      >
        <Box
          ref={containerRef}
          sx={{
            position: 'relative',
            width: '60px',
            height: '60px',
            lineHeight: '60px',

            color: '#fff',
          }}
        ></Box>
      </motion.div>
    </Button>
  )
})

export default Burger
