import { createContext, useContext } from 'react'

import RootStore from './root'

const rootStore = new RootStore()

export const storesContext = createContext({
  appStore: rootStore.appStore,
})

export const useStores = () => useContext(storesContext)
