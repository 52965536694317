import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import Hls from 'hls.js'
import raf from 'raf'
import { isNil } from 'ramda'

class PlayerState {
  progress = 0
  videoRef = undefined
  src = undefined
  stopped = false

  // events
  isMuted = true
  isPlaying = false

  constructor() {
    makeObservable(this, {
      // Observables
      progress: observable,
      videoRef: observable,
      src: observable,
      isMuted: observable,
      isPlaying: observable,

      seek: action,
      tick: action,
      play: action,
      pause: action,
      mute: action,
      unmute: action,
    })
  }

  init(videoRef, src) {
    runInAction(() => {
      this.videoRef = videoRef
      this.src = src
    })

    if (!Hls.isSupported()) {
      console.error('Hls is not supported on this device')
      return
    }

    const config = {
      startLevel: 4,
    }

    const _hls = new Hls(config)

    // Bind to dom element
    _hls.attachMedia(videoRef.current)

    // Setup initialization events
    _hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      // console.info('hls.js bound to video element')
    })

    _hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
      console.info('hls manifest loaded:', data)
      // Play video
      // videoRef.current?.play()
      this.play()
      this.tick()
    })

    // Error handling
    _hls.on(Hls.Events.ERROR, (event, data) => {
      const errorType = data.type
      const errorDetails = data.details
      const errorFatal = data.fatal

      console.error('hls playback error:', errorDetails)
    })

    this.setupVideoEvents(videoRef)

    // Load source
    _hls.loadSource(src)

    this.videoRef = videoRef
    this.hls = _hls
  }

  seek(pct) {
    if (isNaN(pct)) {
      return
    }

    const duration = this.videoRef.current?.duration

    this.videoRef.current.currentTime = pct * duration
  }

  teardown() {
    this.videoRef?.current?.pause()
    this.videoRef?.current?.removeEventListener('pause', this.onPause)
    this.videoRef?.current?.removeEventListener('play', this.onPlay)
    this.videoRef?.current?.removeEventListener(
      'ontimeupdate',
      this.onTimeUpdate
    )

    this.videoRef?.current?.removeEventListener(
      'volumechange',
      this.onVolumeChange
    )

    runInAction(() => {
      this.stopped = true
    })
    raf.cancel(this.tick)

    // https://stackoverflow.com/questions/3258587/how-to-properly-unload-destroy-a-video-element
    this.videoRef?.current?.removeAttribute('src')
    this.videoRef?.current?.load()
  }

  tick() {
    if (this.stopped) {
      return
    }

    const currentTime = this.videoRef.current?.currentTime
    const duration = this.videoRef.current?.duration

    if (isNil(currentTime) || isNil(duration)) {
      raf(this.tick.bind(this))
      return
    }

    const progress = currentTime / duration

    this.progress = progress

    raf(this.tick.bind(this))
  }

  setupVideoEvents(videoRef) {
    videoRef?.current?.addEventListener('play', this.onPlay.bind(this))
    videoRef?.current?.addEventListener('pause', this.onPause.bind(this))
    videoRef?.current.addEventListener(
      'volumechange',
      this.onVolumeChange.bind(this)
    )

    videoRef?.current?.addEventListener(
      'timeupdate',
      this.onTimeUpdate.bind(this)
    )
  }

  onTimeUpdate(event) {
    const currentTime = this.videoRef.current?.currentTime
    const duration = this.videoRef.current?.duration
    const progress = currentTime / duration
    runInAction(() => {
      this.progress = progress
    })
  }

  onPlay(event) {
    runInAction(() => {
      this.isPlaying = true
    })
  }

  onPause(event) {
    runInAction(() => {
      this.isPlaying = false
    })
  }

  onVolumeChange(event) {
    runInAction(() => {
      this.isMuted = this.videoRef?.current?.muted ?? true
    })
  }

  play() {
    this.videoRef?.current?.play()
    // runInAction(() => {})
  }

  pause() {
    this.videoRef?.current?.pause()
  }

  mute() {
    this.videoRef.current.muted = true
  }

  unmute() {
    this.videoRef.current.muted = false
  }
}

export default PlayerState
