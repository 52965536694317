import React from 'react'

// Components
import { Box, Flex } from 'theme-ui'
import { Column } from '@/components/Column'
import Email from '@/components/Svg/Email'
import Grid from '@/components/Grid'
import Instagram from '@/components/Svg/Instagram'
import Section from '@/components/Section'
import { SmallHeaderLight } from '@/components/Header'
import Twitter from '@/components/Svg/Twitter'

// Helpers
import pctString from '@/helpers/pctString'

const Footer = ({ ...rest }) => (
  <Section
    sx={{
      pt: 3,
      bg: 'darkGray',
      color: 'white',
      overflow: 'visible',
      mt: 0,
    }}
  >
    <Grid
      sx={{
        pt: 2,
        pb: 2,
      }}
    >
      <Column width={[pctString(1 / 1), pctString(1 / 1), pctString(1 / 2)]}>
        <SmallHeaderLight
          color="white"
          sx={{ borderTop: '2px solid white', pt: 1 }}
        >
          <Flex
            sx={{
              mb: 1,
              fontSize: 2,
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box sx={{ width: '100px' }}>email</Box>
            <a
              href="mailto:studio@linebreak.studio"
              sx={{
                color: 'white !important',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Box sx={{}}>studio@linebreak.studio</Box>
            </a>
          </Flex>

          {/* Instagram */}
          <Flex
            sx={{
              mb: 1,
              fontSize: 2,
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box sx={{ width: '100px' }}>instagram</Box>
            <a
              href="https://www.instagram.com/linebreak.studio/"
              sx={{
                color: 'white !important',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Box sx={{}}>@linebreak.studio</Box>
            </a>
          </Flex>

          {/* Twitter */}
          <Flex
            sx={{
              mb: 1,
              fontSize: 2,
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box sx={{ width: '100px' }}>twitter</Box>
            <a
              href="https://twitter.com/studiolinebreak"
              sx={{
                color: 'white !important',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Box sx={{}}>@studiolinebreak</Box>
            </a>
          </Flex>
        </SmallHeaderLight>
      </Column>
      <Column
        width={[pctString(1 / 1), pctString(1 / 1), pctString(1 / 2)]}
        sx={{
          pt: [0, 4, 6],
        }}
      >
        <Box sx={{ borderTop: '2px solid white', pt: 1, fontSize: 2 }}>
          © 2013 ~ 2022 Line Break, LLC.
        </Box>
      </Column>
    </Grid>
  </Section>
)

export default Footer
