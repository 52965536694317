import { Box, useThemeUI } from 'theme-ui'
import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'
import RSplitEvery from 'ramda/src/splitEvery'
import Rpath from 'ramda/src/path'
import RpathOr from 'ramda/src/pathOr'
import { motion } from 'framer-motion'

// Components
import A from '@/components/A'
import { Column } from '@/components/Column'
import Grid from '@/components/Grid'
import Line from '@/components/Line'
import P from '@/components/P'
import Section from '@/components/Section'
import {
  HeroHeader,
  HeroHeaderWithLine,
  SmallHeader,
  MediumHeader,
  LargeHeader,
  MediumHeaderWithLine,
  LargeHeaderWithLine,
} from '@/components/Header'
import withPageTransition from '@/components/withPageTransition'
import Wordmark from '@/components/Wordmark'

// Theme
import * as theme from '@/theme'

// Helpers
import pctString from '@/helpers/pctString'

const ClientsList = ({ clients = [] }) => {
  return (
    <ul
      css={{
        padding: 0,
        margin: 0,
        listStyleType: 'none',
      }}
    >
      {clients.map((client) => (
        <li key={client.id}>
          <A
            sx={{
              display: 'inline-block',
              color: 'mediumGray',

              fontSize: [1, 2, 2],
              '&:visited': {
                color: 'mediumGray',
              },
            }}
            href={client.link}
            alt={client.name}
            target="_blank"
          >
            {client.name}
          </A>
        </li>
      ))}
    </ul>
  )
}

const Partner = ({ name, bio }) => (
  <motion.div>
    <SmallHeader
      sx={{
        mb: [0],
        mt: [1],
        borderTop: '3px solid',
        color: 'mediumGray',
        borderColor: 'mediumGray',
      }}
    >
      {name}
    </SmallHeader>
    <P
      sx={{
        mb: [2],
        mt: [0],
        fontFamily: 'body',
      }}
    >
      Partner, co-founder
    </P>

    <P>{bio}</P>
  </motion.div>
)

const ClientsColumn = ({ clients, ...rest }) => (
  <Column {...rest}>
    <ClientsList clients={clients}></ClientsList>
  </Column>
)

const About = ({ data }) => {
  const { theme } = useThemeUI()

  const clients = RpathOr([], ['clients', 'edges'], data).map(
    (edge) => edge.node
  )

  clients.sort((a, b) => {
    return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  })

  const twoColumnClients = RSplitEvery(clients.length / 2, clients)

  const teamImage = data?.teamAlt?.childImageSharp?.gatsbyImageData

  return (
    <Box>
      <Helmet>
        <title>About - Line Break</title>
      </Helmet>
      <Section offset>
        <Grid>
          <Column width={['100%', '100%', pctString(1 / 2)]}>
            <motion.div
              key="about-header"
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{
                // ease: 'easeInOut',
                duration: theme.timings.pageTransition,
              }}
            >
              <LargeHeaderWithLine
                sx={{
                  mt: 0,
                  color: 'mediumGray',
                }}
                css={{ userSelect: 'none' }}
              >
                About
              </LargeHeaderWithLine>
            </motion.div>
          </Column>
          <Column
            width={[0, 0, pctString(6 / 16)]}
            sx={{ display: ['none', 'none', 'inline-block'] }}
          ></Column>
          <Column
            width={[0, 0, pctString(3 / 16)]}
            sx={{ display: ['none', 'none', 'inline-block'] }}
          ></Column>
          <Column width={['100%', '100%', pctString(12 / 16)]}>
            <motion.div
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              exit={{ opacity: 0.0 }}
              transition={{
                // ease: 'easeInOut',
                delay: theme.timings.pageTransition,
                duration: theme.timings.textTransition,
              }}
            >
              <MediumHeaderWithLine sx={{ mt: 0, color: 'mediumGray' }}>
                Line Break is a design & technology studio based in New York
                City.
              </MediumHeaderWithLine>
            </motion.div>
            <motion.div
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              exit={{ opacity: 0.0 }}
              transition={{
                // ease: 'easeInOut',
                delay:
                  theme.timings.pageTransition +
                  theme.timings.textTransition / 2,
                duration: theme.timings.textTransition,
              }}
            >
              <P sx={{ mt: 2 }}>
                We create meaningful human experiences at the intersection of
                art, design, and engineering. Our work encompasses a wide
                variety of technologies and concerns — from physical
                installations and exhibitions to websites, mobile apps, mixed
                reality experiences. and more.
              </P>
            </motion.div>
          </Column>

          {/* Spacer */}
          {/* <Column width={['100%', '100%', pctString(8 / 16)]} /> */}

          <Column
            width={['100%', '100%', pctString(12 / 16)]}
            sx={{
              mt: [2, 2, 2],
              // pt: [4, 4, 4, 4],
              mb: [0],
            }}
            mb={0}
          >
            <motion.div
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              exit={{ opacity: 0.0 }}
              transition={{
                // ease: 'easeInOut',
                delay:
                  theme.timings.pageTransition + theme.timings.textTransition,
                duration: theme.timings.textTransition,
              }}
            >
              <GatsbyImage
                image={teamImage}
                objectFit="cover"
                objectPosition="50% 50%"
                loading="lazy"
                alt="Line Break team"
              />
            </motion.div>
          </Column>

          {/* Spacer */}
          <Column
            width={[0, 0, pctString(4 / 16)]}
            sx={{
              display: ['none', 'none', 'inline-block'],
            }}
          />

          {/* Nien */}
          <Column
            sx={{}}
            width={[pctString(8 / 16), pctString(8 / 16), pctString(6 / 16)]}
            mb={2}
            css={{ position: 'relative' }}
          >
            <motion.div
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              exit={{ opacity: 0.0 }}
              transition={{
                // ease: 'easeInOut',
                delay:
                  theme.timings.pageTransition +
                  theme.timings.textTransition * 1.5,
                duration: theme.timings.textTransition,
              }}
            >
              <Partner
                name="Nien Lam"
                bio="Prior to Line Break, Nien worked as a lead engineer at Bloomberg, where he designed real-time applications for data
visualization and analysis. He holds a B.S. in computer engineering from the University of Southern California and a graduate
degree from NYU in interactive media."
              />
            </motion.div>
          </Column>

          {/* Sebastian */}
          <Column
            sx={{}}
            width={[pctString(8 / 16), pctString(8 / 16), pctString(6 / 16)]}
            mb={2}
            css={{ position: 'relative' }}
          >
            <motion.div
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              exit={{ opacity: 0.0 }}
              transition={{
                // ease: 'easeInOut',
                delay:
                  theme.timings.pageTransition +
                  theme.timings.textTransition * 1.5,
                duration: theme.timings.textTransition,
              }}
            >
              <Partner
                name="Sebastian Buys"
                bio="Sebastian began his career as a strategy consultant at Bain & Company. He holds a B.S. in Business from the University of Southern California and a graduate degree from NYU in
interactive media."
              />
            </motion.div>
          </Column>

          {/* */}
          {/* <Column
            width={[pctString(16 / 16), pctString(16 / 16), pctString(12 / 16)]}
            sx={{}}
          >
            <motion.div
              initial={{ opacity: 0.0 }}
              animate={{ opacity: 1.0 }}
              exit={{ opacity: 0.0 }}
              transition={{
                // ease: 'easeInOut',
                delay:
                  theme.timings.pageTransition +
                  theme.timings.textTransition * 2,
                duration: theme.timings.textTransition,
              }}
            >
              <P sx={{ mt: 2 }}>
                Sebastian and Nien teach at{' '}
                <a href="https://tisch.nyu.edu/itp" target="_blank">
                  NYU’s Tisch School of the Arts
                </a>
                , where they lead courses in design, computer programming, and
                special topics. The team has developed curriculum for{' '}
                <a href="https://engineering.nyu.edu/" target="_blank">
                  NYU's Tandon School of Engineering
                </a>{' '}
                in user experience design and spatial computing / mixed reality.
              </P>
            </motion.div>
          </Column> */}
        </Grid>
      </Section>

      {/* Awards & Recognition */}
      {/* <Section offset>
        <Grid>
          <Column width={['100%', '100%', pctString(6 / 16)]}>
            <LargeHeaderWithLine
              color="darkGray"
              sx={{
                mt: 0,
              }}
              css={{ userSelect: 'none' }}
            >
              Awards & Recognition
            </LargeHeaderWithLine>
          </Column>
          <ClientsColumn
            width={['100%', pctString(1 / 2), pctString(5 / 16)]}
            clients={twoColumnClients[0]}
          />
          <ClientsColumn
            width={['100%', pctString(1 / 2), pctString(5 / 16)]}
            clients={twoColumnClients[1]}
          />
        </Grid>
      </Section> */}

      {/* Clients */}
      <Section>
        <Grid sx={{ mb: 0 }}>
          <Column sx={{ mb: 2 }} width={['100%', '100%', pctString(12 / 16)]}>
            <MediumHeaderWithLine
              color="mediumGray"
              sx={{
                mt: 0,
                mb: 2,
              }}
              css={{ userSelect: 'none' }}
            >
              Select Clients
            </MediumHeaderWithLine>
          </Column>
        </Grid>
        <Grid sx={{ mb: 4 }}>
          <ClientsColumn
            sx={{ mb: 0 }}
            width={['100%', pctString(1 / 2), pctString(5 / 16)]}
            clients={twoColumnClients[0]}
          />
          <ClientsColumn
            sx={{ mb: 0 }}
            width={['100%', pctString(1 / 2), pctString(5 / 16)]}
            clients={twoColumnClients[1]}
          />
        </Grid>
      </Section>

      {/** Teaching */}
      <Section>
        <Grid sx={{ mb: 0 }}>
          <Column sx={{ mb: 2 }} width={['100%', '100%', pctString(12 / 16)]}>
            <MediumHeaderWithLine
              color="mediumGray"
              sx={{
                mt: 0,
                mb: 2,
              }}
              css={{ userSelect: 'none' }}
            >
              Teaching
            </MediumHeaderWithLine>
          </Column>
        </Grid>

        <Grid sx={{}}>
          <Column sx={{ mb: 0 }} width={['100%', '100%', pctString(3 / 16)]}>
            <Box sx={{ fontSize: [1, 2, 2], fontFamily: 'heading' }}>
              UX Design for AR/VR
            </Box>
          </Column>
          <Column sx={{ mb: 3 }} width={['100%', '100%', pctString(8 / 16)]}>
            <Box sx={{ fontSize: [1, 2, 2] }}>
              NYU Tandon School of Engineering
            </Box>
            <Box sx={{ fontSize: [1, 2, 2] }}>2021</Box>
          </Column>
        </Grid>

        <Grid sx={{}}>
          <Column sx={{ mb: 0 }} width={['100%', '100%', pctString(3 / 16)]}>
            <Box sx={{ fontSize: [1, 2, 2], fontFamily: 'heading' }}>
              Augmented Spaces
            </Box>
          </Column>
          <Column sx={{}} width={['100%', '100%', pctString(8 / 16)]}>
            <Box sx={{ fontSize: [1, 2, 2] }}>
              NYU Tisch School of the Arts - ITP
            </Box>
            <Box sx={{ fontSize: [1, 2, 2] }}>2021</Box>
          </Column>
        </Grid>

        <Grid sx={{}}>
          <Column sx={{ mb: 0 }} width={['100%', '100%', pctString(3 / 16)]}>
            <Box sx={{ fontSize: [1, 2, 2], fontFamily: 'heading' }}>
              New Realities
            </Box>
          </Column>

          <Column sx={{}} width={['100%', '100%', pctString(8 / 16)]}>
            <Box sx={{ fontSize: [1, 2, 2] }}>
              NYU Tisch School of the Arts - IMA
            </Box>
            <Box sx={{ fontSize: [1, 2, 2] }}>2020</Box>
          </Column>
        </Grid>

        <Grid sx={{ mb: 5 }}>
          <Column sx={{ mb: 0 }} width={['100%', '100%', pctString(3 / 16)]}>
            <Box sx={{ fontSize: [1, 2, 2], fontFamily: 'heading' }}>
              Mobile Lab
            </Box>
          </Column>
          <Column sx={{}} width={['100%', '100%', pctString(8 / 16)]}>
            <Box sx={{ fontSize: [1, 2, 2] }}>
              NYU Tisch School of the Arts - ITP
            </Box>
            <Box sx={{ fontSize: [1, 2, 2] }}>2018 - 2020</Box>
          </Column>
        </Grid>
      </Section>

      {/* <Section>
        <Grid>
          <Column width={['100%', '100%', pctString(6 / 16)]}>
            <LargeHeaderWithLine
              color="darkGray"
              sx={{
                mt: 0,
              }}
              css={{ userSelect: 'none' }}
            >
              Services
            </LargeHeaderWithLine>
          </Column>

          <Column width={['100%', '100%', pctString(9 / 16)]}></Column>

          <Column width={['100%', '100%', pctString(2 / 16)]}></Column>

          <Column
            width={[pctString(1 / 1), pctString(1 / 2), pctString(5 / 16)]}
          >
            <MediumHeaderWithLine sx={{ mt: 0, color: 'darkGray' }}>
              Design
            </MediumHeaderWithLine>
            <h2>User Experience Design</h2>
            <h2>User Interface Design</h2>
            <h2>Data Visualisation</h2>
            <h2>Installations</h2>
            <h2>Exhibitions</h2>
          </Column>
          <Column
            width={[pctString(1 / 1), pctString(1 / 2), pctString(5 / 16)]}
          >
            <MediumHeaderWithLine sx={{ mt: 0, color: 'darkGray' }}>
              Technologies
            </MediumHeaderWithLine>
            <h2>Web</h2>
            <h2>iOS, MacOS, tvOS, watchOS</h2>
            <h2>AR, VR, XR</h2>
            <h2>Physical computing</h2>
          </Column>
        </Grid>
      </Section> */}

      {/* <Section offset>
        <Grid>
          <Column>test</Column>
        </Grid>
      </Section> */}
    </Box>
  )
}

export default withPageTransition(About, 'about-page')

export const query = graphql`
  query AboutPageQuery {
    team: file(relativePath: { eq: "linebreak-team.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    teamAlt: file(relativePath: { eq: "linebreak-team-alt.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    clients: allContentfulClient {
      edges {
        node {
          id
          name
          link
        }
      }
    }
  }
`
