const Email = (props) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M2,6v20h28V6H2z M25.8,8L16,14.8L6.2,8H25.8z M4,24V8.9l12,8.3l12-8.3V24H4z" />
  </svg>
)

export default Email
