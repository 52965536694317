import { Box, Flex } from 'theme-ui'
import { observer } from 'mobx-react'
import { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import useMouse from '@react-hook/mouse-position'

const Scrubber = observer(({ playerState, ...rest }) => {
  const mouseRef = useRef()
  const mouse = useMouse(mouseRef)

  useEffect(() => {
    // console.log(mouse)
    if (mouse.isDown) {
      playerState.seek(mouse.x / mouse.elementWidth)
    }
  }, [mouse.isDown])

  return (
    <Flex
      sx={{
        height: '100%',
        alignItems: 'center',
        bg: 'transparent',
      }}
      {...rest}
    >
      <Flex
        ref={mouseRef}
        sx={{
          bg: 'darkGray',
          width: '100%',
          height: '10px',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '10px',
            bg: 'lightGray',
            transformOrigin: 'center left',
            transform: `scale3d(${playerState.progress}, 1, 1)`,
          }}
        />
      </Flex>
    </Flex>
  )
})

export default Scrubber
