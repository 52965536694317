import React from 'react'
import { Flex } from 'theme-ui'

const Grid = ({ ...props }) => (
  <Flex
    mx={[2]}
    sx={{
      flexWrap: 'wrap',
    }}
    {...props}
  />
)

export default Grid
